import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import background from '../../assets/webp/fromJPG/homePageDotsWave.webp';
import tailImage from '../../assets/webp/fromPNG/tail-10_1x.webp';
import { Button } from '../../components/Button';
import { colors } from '../../theme/default/color';
import { handleScroll } from '../../utils/handelScrollToRef';
import type { BlockchainHubHeroProps } from './BlockchainHubHero.type';

const PREFIX = 'BlockchainHubHero';

const classes = {
  root: `${PREFIX}-root`,
  heroTextContainer: `${PREFIX}-heroTextContainer`,
  heroImageContainer: `${PREFIX}-heroImageContainer`,
  heroTextPink: `${PREFIX}-heroTextPink`,
  heroButtonContainer: `${PREFIX}-heroButtonContainer`,
};

const HubHeroContainer = styled('section')(
  ({ theme: { spacing, palette, breakpoints } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    color: palette.primary.main,
    backgroundImage: `url(${background})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    position: 'relative',
    padding: spacing(10),
    [breakpoints.only('sm')]: {
      padding: spacing(14, 0),
    },
    [breakpoints.only('xs')]: {
      padding: spacing(8, 0),
      gridTemplateColumns: '1fr',
    },

    [`& .${classes.heroTextContainer}`]: {
      margin: '2rem auto 0',

      [breakpoints.down('md')]: {
        marginLeft: '2rem',
      },

      [breakpoints.only('xs')]: {
        textAlign: 'center',
        gap: spacing(5),
        margin: spacing(0, 2),
      },
    },

    [`& .${classes.heroImageContainer}`]: {
      display: 'grid',
      justifyContent: 'center',
      alignContent: 'center',

      [breakpoints.only('xs')]: {
        gridRow: '1 / span 1',
        marginTop: '-18rem',
      },
      ['& img']: {
        width: '100%',
        height: 'auto',
      },
    },

    [`& .${classes.heroTextPink}`]: {
      color: colors.pink,
    },

    [`& .${classes.heroButtonContainer}`]: {
      marginTop: '4rem',
      [breakpoints.only('xs')]: {
        '& > button': {
          width: '100%',
        },
      },
    },
  })
);

export const BlockchainHubHero: React.FC<BlockchainHubHeroProps> = ({
  scrollRef,
}) => {
  return (
    <HubHeroContainer>
      <div className={classes.heroTextContainer}>
        <Typography sx={{ lineHeight: '1.3' }} mb={5} variant="h1">
          Join Rumble Fish
          <br />
          <span className={classes.heroTextPink}>Blockchain Training Hub!</span>
        </Typography>
        <Typography
          variant="h2"
          my={2}
          sx={{
            color: colors.navyBlue,
            fontSize: { xs: '1.8rem', md: '2.2rem' },
            lineHeight: { xs: '2.2rem' },
          }}>
          A course designed by Marek Kowalski,
          <br />
          CTO at Rumble Fish
        </Typography>
        <Typography
          variant="caption"
          sx={{
            textTransform: 'unset',
            fontSize: '1.15rem',
            letterSpacing: '0.1em',
          }}>
          Learn Blockchain and get paid for it!
        </Typography>
        <div className={classes.heroButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleScroll(scrollRef)}>
            Apply Now!
          </Button>
        </div>
      </div>

      <div className={classes.heroImageContainer}>
        <img alt="" width={468} height={480} src={tailImage} />
      </div>
    </HubHeroContainer>
  );
};
