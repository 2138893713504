import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React, { useState, useRef, useCallback, useContext } from 'react';
import type { CustomizedSelectProps } from './CustomizedSelect.types';
import {
  StyledFormControl,
  StyledInput,
  StyledInputLabel,
  StyledMenuItem,
} from './styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { themeDark } from '@rumblefish/ui/src/theme/rumblefish23Theme';
import { ThemeContext } from '@rumblefish/ui/context/themeContext/themeContext';
import type { ThemeContextType } from '@rumblefish/ui/context/themeContext/theme.Context.types';

export const CustomizedSelect = ({
  valueType,
  title,
  items,
  initialValue,
  emptyValue = valueType === 'string' ? '' : 0,
  onChange,
  textOnEmpty = 'no items in the list',
  sx,
}: CustomizedSelectProps): ReactJSXElement => {
  const { changeScrollTarget } = useContext(ThemeContext) as ThemeContextType;
  const [value, setValue] = useState<typeof valueType>(
    initialValue || emptyValue
  );
  const selectEl = useRef<HTMLSelectElement>(null);

  const handleOpenMenu = useCallback(() => {
    changeScrollTarget(selectEl.current);
  }, [selectEl, changeScrollTarget]);

  const handleCloseMenu = useCallback(() => {
    changeScrollTarget(null);
  }, [changeScrollTarget]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<typeof valueType>) => {
      const newValue = event.target.value;
      setValue(newValue);
      onChange(newValue);
    },
    [onChange, setValue]
  );

  return (
    <>
      <StyledFormControl variant="standard">
        {title && (
          <StyledInputLabel id={`${title}-select-label`}>
            {title}
          </StyledInputLabel>
        )}
        <Select
          labelId={`${title}-select-label`}
          id={`${title}-select`}
          value={value}
          displayEmpty
          ref={selectEl}
          onChange={handleChange}
          input={<StyledInput />}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          sx={sx}
          MenuProps={{
            sx: {
              '& .MuiList-root': {
                background: themeDark.palette.background.default,
                maxHeight: '200px',
                overflow: 'auto',
              },
            },
          }}>
          <StyledMenuItem value="">
            {items.length === 0 ? textOnEmpty : 'All'}
          </StyledMenuItem>
          {items.map((item, index) => (
            <StyledMenuItem
              key={
                title
                  ? `${title}${item.value}${index}`
                  : `${item.value}${index}`
              }
              value={item.value}>
              {item.label}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </>
  );
};
