import { combineReducers } from 'redux';

import { errorSlice, telegramBtnSlice } from './reducers';

const rootReducer = combineReducers({
  errorMsg: errorSlice.reducer,
  telegramBtn: telegramBtnSlice.reducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
