import { type StackProps, Stack } from '@mui/material';
import { Section } from '@rumblefish/ui/AlgeaTheme';
import React from 'react';
import logoCircle from '../../assets/webp/fromPNG/fintechGuide/logoCircle.webp';
import connectYourCareLogo from '../../assets/webp/fromPNG/fintechGuide/startups/connectYourCareLogo.webp';
import daoMakerLogo from '../../assets/webp/fromPNG/fintechGuide/startups/daoMakerLogo.webp';
import solarGenerationLogo from '../../assets/webp/fromPNG/fintechGuide/startups/solarGenerationLogo.webp';
import spaceInchLogo from '../../assets/webp/fromPNG/fintechGuide/startups/spaceInchLogo.webp';
import verifyIdLogo from '../../assets/webp/fromPNG/fintechGuide/startups/verifyIdLogo.webp';
import zbayLogo from '../../assets/webp/fromPNG/fintechGuide/startups/zbayLogo.webp';
import { FintechEbookForm } from '../FintechEbookForm';
import { StartupIcon } from './StartupIcon/StartupIcon';
import {
  StyledStack,
  StyledBlocksStack,
  StyledHeading,
  Block,
  BlockIconStack,
  Chequered,
  IconCircleStack,
  StyledBlockTitle,
  StyledBlockDescription,
  StyledIcon,
  Line,
  StyledNumberText,
  StartupsStyledStack,
  StartupHeading,
  CurrentStartupStyledStack,
  FreeEbookStack,
  FreeEbookHeading,
} from './styles';

export const FintechSixWaysSection = ({ ...props }: StackProps) => {
  const blocks: Array<{ title: string; desc: string }> = [
    {
      title: 'Know where to start the development of your fintech MVP',
      desc: 'The first step is always the most challenging part of the process. Know exactly where to start and which direction to take with our tips and tricks!',
    },
    {
      title: 'Learn the stages of building your fintech MVP',
      desc: 'With our e-book, you’ll learn the possible timeline of the fintech MVP development process. Don’t let any surprises distract you from the goal.',
    },
    {
      title:
        'Learn how to choose the suitable tech stack for your fintech project',
      desc: 'Building a fintech mobile app or a cryptocurrency exchange? Learn what type of tech tools knowledge, programming languages, and experience should your IT team have.',
    },
    {
      title: 'Find out the best UI practices to apply in your fintech MVP',
      desc: 'Building a fintech mobile app or a cryptocurrency exchange? Learn what type of tech tools knowledge, programming languages, and experience should your IT team have.',
    },
    {
      title: 'Learn fintech product launch strategies',
      desc: 'See the most popular and effective launch strategies for your fintech product.',
    },
    {
      title:
        'Find out the fintech trends that’ll dominate the market in the years to come',
      desc: 'See if your fintech MVP concept matches what experts are thinking will be the hype in the upcoming years.',
    },
  ];

  return (
    <Section width="small" backgroundColor="transparent" positionRelativeOn>
      <StyledStack {...props}>
        <Chequered />
        <StyledIcon src={logoCircle} />
        <StyledHeading>
          6 ways this e-book helps your
          <br />
          fintech MVP development journey
        </StyledHeading>
        <StyledBlocksStack>
          {blocks.map((block, index) => (
            <Block key={index}>
              <BlockIconStack>
                <IconCircleStack>
                  <Stack
                    width={50}
                    height={50}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    <StyledNumberText>{index + 1}</StyledNumberText>
                  </Stack>
                </IconCircleStack>
              </BlockIconStack>
              <StyledBlockTitle>{block.title}</StyledBlockTitle>
              <StyledBlockDescription>{block.desc}</StyledBlockDescription>
            </Block>
          ))}
        </StyledBlocksStack>
        <StartupsStyledStack>
          <StartupHeading>
            Check out those successful companies
            <br />
            that worked with us and join them
          </StartupHeading>

          <CurrentStartupStyledStack>
            <StartupIcon
              imageSrc={spaceInchLogo}
              imageAlt="spaceInchLogo"
              url="https://spaceinch.com/"
            />
            <StartupIcon
              imageSrc={zbayLogo}
              imageAlt="zbayLogo"
              url="https://www.rumblefish.dev/case-studies/zbay-a-private-inbox-wallet-and-marketplace-all-in-one/"
            />
            <StartupIcon
              imageSrc={verifyIdLogo}
              imageAlt="verifyIdLogo"
              url="https://www.rumblefish.dev/case-studies/verifiid/"
            />
            <StartupIcon
              imageSrc={connectYourCareLogo}
              imageAlt="connectYourCareLogo"
              url="https://www.rumblefish.dev/case-studies/connectyourcare/"
            />
            <StartupIcon
              imageSrc={solarGenerationLogo}
              imageAlt="solarGenerationLogo"
              url="https://www.rumblefish.dev/case-studies/solar-generation-a-global-energy-online-trading-platform-mvp/"
            />
            <StartupIcon
              imageSrc={daoMakerLogo}
              imageAlt="daoMakerLogo"
              url="https://www.rumblefish.dev/case-studies/makerdao/"
            />
          </CurrentStartupStyledStack>
        </StartupsStyledStack>
        <FreeEbookStack>
          <FreeEbookHeading>Yes, I want a free e-book</FreeEbookHeading>
          <FintechEbookForm />
        </FreeEbookStack>
      </StyledStack>
    </Section>
  );
};
