import { CssBaseline, ThemeProvider } from '@mui/material';
import Lenis from '@studio-freight/lenis';
import React, { useCallback, useContext, useEffect } from 'react';

import type { ThemeContextType } from '../../../../context/themeContext/theme.Context.types';
import { ThemeContext } from '../../../../context/themeContext/themeContext';
import { themeDark } from '../../../theme/rumblefish23Theme';
import { Footer } from '../Footer';
import { Header } from '../Header';
import type { LayoutProps } from './Layout.types';
import { useRouterEventually } from '../../../hooks';

export const Layout = ({
  blogPosts,
  useLenis = true,
  children,
}: LayoutProps) => {
  const router = useRouterEventually();
  const { scrollTarget, lenis, setLenis } = useContext(
    ThemeContext
  ) as ThemeContextType;
  const raf = useCallback(
    (time: number) => {
      lenis?.raf(time);
      requestAnimationFrame(raf);
    },
    [lenis]
  );

  useEffect(() => {
    if (scrollTarget && document.body) {
      document.body.style.overflow = 'hidden';
    } else document.body.style.overflow = 'auto';

    if (useLenis) {
      if (!lenis)
        setLenis(
          new Lenis(
            !scrollTarget
              ? { lerp: 0.08 }
              : { smoothWheel: false, wrapper: scrollTarget as HTMLElement }
          )
        );

      requestAnimationFrame(raf);
      return () => {
        lenis?.destroy();
      };
    }
  }, [useLenis, lenis, raf, scrollTarget, setLenis]);

  useEffect(() => {
    if (lenis) {
      lenis.scrollTo(0, {
        immediate: true,
      });
    }
  }, [router?.asPath]);

  return (
    <ThemeProvider theme={themeDark}>
      <CssBaseline>
        <Header blogPosts={blogPosts} />
        {children}
        <Footer />
      </CssBaseline>
    </ThemeProvider>
  );
};
