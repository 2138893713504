import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';

import {
  StyledText,
  StyledQuote,
  StyledStack,
  StyledPosition,
  StyledTextStack,
  StyledCapionStack,
  StyledFooterStack,
  StyledContentStack,
  StyledMobileImageStack,
  StyledDesktopImageStack,
} from './styles';
import type {
  TestimonialProps,
  TestimonialContentProps,
} from './Testimonial.types';

const TestimonialContent = ({
  name,
  positionInCompany,
  text,
  avatarSrc,
}: TestimonialContentProps) => {
  return (
    <>
      <StyledDesktopImageStack>
        <img src={avatarSrc} alt={`${name}_TestimonialAvatar`} />
      </StyledDesktopImageStack>
      <StyledContentStack showBorder={text.length < 450}>
        <StyledQuote variant="display_lg">“</StyledQuote>
        <StyledTextStack>
          <StyledText variant="strong_500">{text}</StyledText>
          <StyledFooterStack>
            <StyledCapionStack>
              <StyledText variant="strong_500">{name}</StyledText>
              <StyledPosition variant="regular_100">
                {positionInCompany}
              </StyledPosition>
            </StyledCapionStack>
            <StyledMobileImageStack>
              <img src={avatarSrc} alt={`${name}_TestimonialAvatar`} />
            </StyledMobileImageStack>
          </StyledFooterStack>
        </StyledTextStack>
      </StyledContentStack>
    </>
  );
};

export const Testimonial = ({
  name,
  positionInCompany,
  text,
  avatarSrc,
  customStyles,
  ...props
}: TestimonialProps): ReactJSXElement => {
  if (customStyles)
    return (
      <StyledStack sx={customStyles}>
        <TestimonialContent
          avatarSrc={avatarSrc}
          name={name}
          positionInCompany={positionInCompany}
          text={text}
        />
      </StyledStack>
    );
  else {
    return (
      <StyledStack {...props}>
        <TestimonialContent
          avatarSrc={avatarSrc}
          name={name}
          positionInCompany={positionInCompany}
          text={text}
        />
      </StyledStack>
    );
  }
};
