import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React, { useState } from 'react';

import {
  IconStack,
  StyledLink,
  StyledName,
  StyledImage,
  StyledStack,
  ContentStack,
  StyledWrapper,
  StyledDescription,
  StyledUnderlinedText,
} from './styles';
import type { SubmenuBlockProps } from './SubmenuBlock.types';

export const SubmenuBlock = ({
  type,
  caption,
  icon,
  name,
  linkTo,
  iconPrefix,
  variant,
  targetBlank = false,
  ...props
}: SubmenuBlockProps): ReactJSXElement => {
  const SubmenuIcon = icon;
  const [isHover, setIsHover] = useState<boolean>(false);
  const hoverClassName = isHover ? 'Hovering' : '';
  const submenuTypeClassName = type === 'icon' ? 'IconType' : 'SquareType';

  const websiteUrl = process.env.NEXT_PUBLIC_PATH as string;
  return (
    <StyledWrapper>
      <StyledLink
        href={linkTo}
        target={`${
          (linkTo.startsWith(websiteUrl) || linkTo.startsWith('/')) &&
          !targetBlank
            ? '_self'
            : '_blank'
        }`}
        rel={`${linkTo.startsWith(websiteUrl) ? '' : 'noopener noreferrer'}`}>
        <StyledStack
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}>
          <IconStack className={`${hoverClassName} ${submenuTypeClassName}`}>
            {typeof icon === 'string' ? (
              <>
                {type === 'icon' ? (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img src={icon}></img>
                ) : (
                  <StyledImage src={icon as string} alt="BlogSubmenuBlogImg" />
                )}
              </>
            ) : (
              <SubmenuIcon
                transitionduration={0.3}
                shouldbegradient={isHover}
                prefix={`${iconPrefix}SubmenuBlock`}
                sx={{ width: '27px', height: '27px' }}
                solidColor
                iconType="bold"
              />
            )}
          </IconStack>
          <ContentStack gap={variant === 'resource' ? 0.2 : undefined}>
            <StyledName
              className={hoverClassName}
              lineHeight={
                variant === 'resource' ? '22px !important' : undefined
              }
              variant={variant === 'resource' ? 'strong_200' : 'strong_300'}>
              <StyledUnderlinedText className={hoverClassName}>
                {name}
              </StyledUnderlinedText>
            </StyledName>
            <StyledDescription
              className={submenuTypeClassName}
              variant="regular_200">
              {caption}
            </StyledDescription>
          </ContentStack>
        </StyledStack>
      </StyledLink>
    </StyledWrapper>
  );
};
