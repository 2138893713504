import { styled, Typography, Stack } from '@mui/material';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.mixins.neutralGradient,
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  paddingBottom: theme.spacing(0.5),
}));
export const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
}));
export const StyledInfoContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));
export const StyledInfoTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
  width: 'min-content',
  whiteSpace: 'nowrap',
}));
export const StyledInfoDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.SECONDARY,
}));

export const StyledTechStackContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}));

export const StyledTechStackItem = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.SECONDARY,
  whiteSpace: 'nowrap',
  border: '1px solid',
  borderRadius: '25px',
  padding: theme.spacing(0.5) + ' ' + theme.spacing(2),
  borderColor: theme.palette.invertedContent?.SECONDARY,
  cursor: 'default',
}));
