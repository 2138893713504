import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Typography, SvgIcon } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {
  memo,
  useRef,
  useEffect,
  useContext,
  useReducer,
  useCallback,
} from 'react';

import type { ThemeContextType } from '../../../../context/themeContext/theme.Context.types';
import { ThemeContext } from '../../../../context/themeContext/themeContext';
import gradientLogo from '../../../assets/svg/RumbleFish23Theme/mainGradientRectangularLogo.svg';
import solidLogo from '../../../assets/svg/RumbleFish23Theme/mainSolidRectangularLogo.svg';
import { breakpoints } from '../../../theme/rumblefish23Theme/breakpoints';
import { CareersSubmenu } from '../CareersSubmenu';
import { HeaderMenuItemWithCollapse } from '../HeaderMenuItemWithCollapse';
import { HeaderSubmenu } from '../HeaderSubmenu';
import { Link } from '../Link';
import { ProductsSubmenu } from '../ProductsSubmenu';
import { BlogSubmenu } from '../BlogSubmenu';
import { ServicesSubmenu } from '../ServicesSubmenu';
import { SUBMENUS, submenusWhichAction } from '../utils/HeaderSubmenusUtils';
import {
  reducer,
  initialState,
  doSetCurrentSub,
  doOnSubmenuClose,
  doOnSubmenuEnter,
  doChangeHoverState,
  doOnSubmenuNameEnter,
  doOnSubmenuNameLeave,
  doSetIsUnwantedTouch,
  doSetShouldCloseSubmenu,
  doChangeCollapseUnmounted,
  doOnSetInitialState as doOnSetInitialStateDesktop,
} from './DesktopHeaderReducer';
import type {
  OnSubmenuHoverType,
  OnSubmenuNameHoverType,
} from './DesktopHeaderReducer';
import type {
  HeaderProps,
  HandleChangeType,
  MobileMenuButtonProps,
  SubmenuHoverArrowProps,
  MobileAccordionElementProps,
} from './Header.types';
import {
  doOnSetExpanded,
  doOnSetNextSubmenu,
  doOnOpenNextSubmenu,
  doOnSetInitialState,
  reducerMobileHeader,
  doOnSetIsSubmenuClose,
  doOnSetShouldOpenMenu,
  initialStateMobileHeader,
} from './MobileHeaderReducer';
import {
  IconStack,
  StyledLogo,
  SubmenuName,
  StyledStack,
  StyledTitle,
  OptionsStack,
  SubmenusStack,
  HeaderSection,
  StyledLogoLink,
  StyledSvgArrow,
  MobileButtonText,
  StyledMuiAccordion,
  MobileButtonWrapper,
  StyledContactButton,
  MobileButtonCloseIcon,
  MobileMenuBorderStack,
  MobileButtonBurgerIcon,
  MobileMenuContentStack,
  StyledAccordionWrapper,
  MobileButtonIconWrapper,
  ImitateHeaderHeightStack,
  StyledMobileMenuCollapse,
  StyledMuiAccordionDetails,
  StyledMuiAccordionSummary,
} from './styles';
import { useRouterEventually } from '../../../hooks';
import clsx from 'clsx';

const MobileMenuButton = ({ onClick, isOpen }: MobileMenuButtonProps) => {
  return (
    <MobileButtonWrapper onClick={onClick}>
      <MobileButtonText variant="strong_100">Menu</MobileButtonText>
      <MobileButtonIconWrapper>
        <MobileButtonCloseIcon
          opacity={!isOpen ? 1 : 0}
          transform={!isOpen ? 'scale(1)' : 'scale(0)'}
        />
        <MobileButtonBurgerIcon
          opacity={isOpen ? 1 : 0}
          transform={isOpen ? 'scale(1)' : 'scale(0)'}
        />
      </MobileButtonIconWrapper>
    </MobileButtonWrapper>
  );
};

const SubmenuHoverArrow = ({ ...props }: SubmenuHoverArrowProps) => {
  return (
    <StyledSvgArrow
      {...props}
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 5L5 1L9 5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledSvgArrow>
  );
};

const ContactButton = (): ReactJSXElement => {
  return (
    <StyledContactButton link="/contact" variant="icon">
      <SvgIcon viewBox="0 0 18 15" xmlns="http://www.w3.org/2000/svg">
        <path
          stroke="currentColor"
          fill="none"
          d="M16.5 3.29329C16.5 3.99829 16.1508 4.65746 15.5667 5.05246L11.8117 7.59829C10.1175 8.74663 7.895 8.74663 6.20083 7.59829L2.44583 5.05246C1.86167 4.65663 1.5125 3.99746 1.5125 3.29246C1.5125 2.11829 2.46417 1.16663 3.63833 1.16663H14.3742C15.5483 1.16663 16.5 2.11829 16.5 3.29329ZM16.5 3.29329V11.1666C16.5 12.5475 15.3808 13.6666 14 13.6666H4C2.61917 13.6666 1.5 12.5475 1.5 11.1666V3.29246"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
      <Typography variant="strong_200">Contact</Typography>
    </StyledContactButton>
  );
};

const DesktopHeader = ({
  blogPosts,
  backgroundColor,
  useSolidColorLogo,
  isMobile,
}: HeaderProps): ReactJSXElement => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleOnSubmenuClose = useCallback(
    (sub: SUBMENUS | null) => {
      dispatch(doOnSubmenuClose(sub));
    },
    [dispatch]
  );
  const handleCollapseUnmounted = useCallback(
    (value: boolean) => {
      dispatch(doChangeCollapseUnmounted(value));
    },
    [dispatch]
  );

  const handleUnwantedTouch = useCallback(
    (value: boolean) => {
      dispatch(doSetIsUnwantedTouch(value));
    },
    [dispatch]
  );
  const handleHoverStateChange = useCallback(
    (type: string, value: boolean) => {
      dispatch(doChangeHoverState(type, value));
    },
    [dispatch]
  );

  const handleCurrentSubChange = useCallback(
    (sub: SUBMENUS | null) => {
      dispatch(doSetCurrentSub(sub));
    },
    [dispatch]
  );

  const handleSetShouldCloseSubmenu = useCallback(
    (value: boolean) => {
      dispatch(doSetShouldCloseSubmenu(value));
    },
    [dispatch]
  );
  const handleOnSubmenuNameEnter = useCallback(
    (values: OnSubmenuNameHoverType) => {
      dispatch(doOnSubmenuNameEnter(values));
    },
    [dispatch]
  );
  const handleOnSubmenuNameLeave = useCallback(
    (values: OnSubmenuNameHoverType) => {
      dispatch(doOnSubmenuNameLeave(values));
    },
    [dispatch]
  );
  const handleOnSubmenuEnter = useCallback(
    (values: OnSubmenuHoverType) => {
      dispatch(doOnSubmenuEnter(values));
    },
    [dispatch]
  );
  const handleOnSetInitialState = useCallback(() => {
    dispatch(doOnSetInitialStateDesktop());
  }, [dispatch]);
  useEffect(() => {
    if (!state.isCollapseUnmounted || !state.currentSubmenu) return;

    if (state.isUnwantedTouch) {
      handleUnwantedTouch(false);
      if (state.prevSubmenu === state.currentSubmenu) {
        handleCurrentSubChange(null);
      }

      return;
    }

    handleHoverStateChange(submenusWhichAction[state.currentSubmenu], true);
  }, [
    handleCurrentSubChange,
    handleHoverStateChange,
    handleUnwantedTouch,
    state,
  ]);

  useEffect(() => {
    if (isMobile) handleOnSetInitialState();
  }, [isMobile]);

  const router = useRouterEventually();

  const currentPath = router?.pathname ?? '';

  const shouldBeSelected = (name: SUBMENUS) => {
    if (state[`${name}Hover`]) return 'CurrentlySelectedSubmenu';
    else return '';
  };

  return (
    <>
      <ImitateHeaderHeightStack />
      <HeaderSection
        width="normal"
        borderBottomOn
        sx={{ backgroundColor: backgroundColor }}>
        <StyledStack>
          <StyledLogoLink to="/">
            <StyledLogo
              alt="desktopRumblefishLogo"
              src={useSolidColorLogo ? solidLogo : gradientLogo}
            />
          </StyledLogoLink>

          <SubmenusStack>
            <HeaderMenuItemWithCollapse
              onSubmenuNameLeave={handleOnSubmenuNameLeave}
              onSubmenuNameEnter={handleOnSubmenuNameEnter}
              onSubmenuClose={handleOnSubmenuClose}
              onSubmenuChange={handleCurrentSubChange}
              state={state}
              submenu={SUBMENUS.SERVICES}>
              <SubmenuName
                isActive={currentPath.startsWith('/services')}
                variant="strong_200"
                className={shouldBeSelected(SUBMENUS.SERVICES)}>
                Services
                <SubmenuHoverArrow
                  className={shouldBeSelected(SUBMENUS.SERVICES)}
                />
              </SubmenuName>
            </HeaderMenuItemWithCollapse>
            <HeaderMenuItemWithCollapse
              onSubmenuNameLeave={handleOnSubmenuNameLeave}
              onSubmenuNameEnter={handleOnSubmenuNameEnter}
              onSubmenuClose={handleOnSubmenuClose}
              onSubmenuChange={handleCurrentSubChange}
              state={state}
              submenu={SUBMENUS.PRODUCTS}>
              <SubmenuName
                isActive={currentPath.startsWith('/products')}
                className={shouldBeSelected(SUBMENUS.PRODUCTS)}
                variant="strong_200">
                Products
                <SubmenuHoverArrow
                  className={shouldBeSelected(SUBMENUS.PRODUCTS)}
                />
              </SubmenuName>
            </HeaderMenuItemWithCollapse>

            <SubmenuName
              isActive={currentPath === '/case-studies'}
              variant="strong_200"
              className={'WithoutSubmenu'}>
              <Link to="/case-studies"> Case studies</Link>
            </SubmenuName>

            <SubmenuName
              isActive={currentPath === '/careers'}
              variant="strong_200"
              className={'WithoutSubmenu'}>
              <Link to="/careers"> Careers</Link>
            </SubmenuName>
            <HeaderMenuItemWithCollapse
              onSubmenuNameLeave={handleOnSubmenuNameLeave}
              onSubmenuNameEnter={handleOnSubmenuNameEnter}
              onSubmenuClose={handleOnSubmenuClose}
              onSubmenuChange={handleCurrentSubChange}
              state={state}
              submenu={SUBMENUS.BLOG}>
              <SubmenuName
                isActive={currentPath.startsWith('/blog')}
                className={shouldBeSelected(SUBMENUS.BLOG)}
                variant="strong_200">
                Blog
                <SubmenuHoverArrow
                  className={shouldBeSelected(SUBMENUS.BLOG)}
                />
              </SubmenuName>
            </HeaderMenuItemWithCollapse>

            <SubmenuName
              isActive={currentPath === '/team'}
              variant="strong_200"
              className={'WithoutSubmenu'}>
              <Link to="/team">About us</Link>
            </SubmenuName>
          </SubmenusStack>
          <OptionsStack>
            <ContactButton />
          </OptionsStack>
        </StyledStack>
        <HeaderSubmenu
          onSubmenuEnter={handleOnSubmenuEnter}
          setShouldCloseSubmenu={handleSetShouldCloseSubmenu}
          state={state}
          onUnwantedTouch={handleUnwantedTouch}
          onHoverStateChange={handleHoverStateChange}
          submenu={SUBMENUS.SERVICES}
          isOpen={state.servicesHover}
          setUnmounted={handleCollapseUnmounted}>
          <ServicesSubmenu />
        </HeaderSubmenu>

        <HeaderSubmenu
          onSubmenuEnter={handleOnSubmenuEnter}
          setShouldCloseSubmenu={handleSetShouldCloseSubmenu}
          state={state}
          onUnwantedTouch={handleUnwantedTouch}
          onHoverStateChange={handleHoverStateChange}
          submenu={SUBMENUS.PRODUCTS}
          isOpen={state.productsHover}
          setUnmounted={handleCollapseUnmounted}>
          <ProductsSubmenu />
        </HeaderSubmenu>
        <HeaderSubmenu
          onSubmenuEnter={handleOnSubmenuEnter}
          setShouldCloseSubmenu={handleSetShouldCloseSubmenu}
          state={state}
          onUnwantedTouch={handleUnwantedTouch}
          onHoverStateChange={handleHoverStateChange}
          submenu={SUBMENUS.CAREERS}
          isOpen={state.careersHover}
          setUnmounted={handleCollapseUnmounted}>
          <CareersSubmenu />
        </HeaderSubmenu>
        <HeaderSubmenu
          onSubmenuEnter={handleOnSubmenuEnter}
          setShouldCloseSubmenu={handleSetShouldCloseSubmenu}
          state={state}
          onUnwantedTouch={handleUnwantedTouch}
          onHoverStateChange={handleHoverStateChange}
          submenu={SUBMENUS.BLOG}
          isOpen={state.blogHover}
          setUnmounted={handleCollapseUnmounted}>
          <BlogSubmenu blogPosts={blogPosts} />
        </HeaderSubmenu>
      </HeaderSection>
    </>
  );
};

const MobileAccordionElement = ({
  name,
  currentExpanded,
  onChange,
  children,
  handleCloseAnimEnd,
  nonExpandable,
  linkTo,
  isActive,
}: MobileAccordionElementProps) => {
  const onChangeParams: HandleChangeType = nonExpandable
    ? { panel: false, link: linkTo }
    : { panel: `panel${name}` };

  const shouldBeExpanded = nonExpandable
    ? false
    : currentExpanded === `panel${name}`;
  return (
    <StyledMuiAccordion
      onTransitionEnd={handleCloseAnimEnd}
      disableGutters
      elevation={0}
      square
      expanded={shouldBeExpanded}
      onChange={onChange(onChangeParams)}>
      <StyledMuiAccordionSummary
        className={clsx(
          !currentExpanded
            ? 'Opaque'
            : currentExpanded === `panel${name}`
            ? 'Opaque'
            : 'Transparent',
          !isActive ? '' : 'Active'
        )}
        expandIcon={
          <IconStack display={nonExpandable ? 'none' : 'flex'}>
            <AddIcon className={!shouldBeExpanded ? 'ShowIcon' : 'HideIcon'} />
            <RemoveIcon
              className={shouldBeExpanded ? 'ShowIcon' : 'HideIcon'}
            />
          </IconStack>
        }
        aria-controls={`panel${name}-menu-content`}
        id={`panel${name}-menu-header`}>
        <StyledTitle variant="strong_500">{name}</StyledTitle>
      </StyledMuiAccordionSummary>
      <StyledMuiAccordionDetails>{children}</StyledMuiAccordionDetails>
    </StyledMuiAccordion>
  );
};

const MobileHeader = ({
  blogPosts,
  backgroundColor,
  useSolidColorLogo,
  isMobile,
}: HeaderProps): ReactJSXElement => {
  const router = useRouterEventually();
  const currentPath = router?.pathname ?? '';
  const [state, dispatch] = useReducer(
    reducerMobileHeader,
    initialStateMobileHeader
  );
  const { changeScrollTarget } = useContext(ThemeContext) as ThemeContextType;

  const AccordionWrapperRef = useRef(null);
  const handleSetShouldOpenMenu = useCallback(
    (val: boolean) => {
      dispatch(doOnSetShouldOpenMenu(val));
    },
    [dispatch]
  );
  const handleSetIsSubmenuClose = useCallback(
    (val: boolean) => {
      dispatch(doOnSetIsSubmenuClose(val));
    },
    [dispatch]
  );
  const handleSetExpanded = useCallback(
    (val: string | false) => {
      dispatch(doOnSetExpanded(val));
    },
    [dispatch]
  );
  const handleSetNextSubmenu = useCallback(
    (val: string | false) => {
      dispatch(doOnSetNextSubmenu(val));
    },
    [dispatch]
  );
  const handleOpenNextSubmenu = useCallback(
    (val: string | false) => {
      dispatch(doOnOpenNextSubmenu(val));
    },
    [dispatch]
  );
  const handleSetInitialState = useCallback(() => {
    dispatch(doOnSetInitialState());
  }, [dispatch]);

  const handleCloseAnimEnd = useCallback(() => {
    if (!state.expanded) handleSetIsSubmenuClose(true);
  }, [state.expanded]);

  const handleChange =
    ({ panel, link }: HandleChangeType) =>
    (_event: React.SyntheticEvent, newExpanded: boolean) => {
      if (!state.expanded || !newExpanded || !panel) {
        const expandedParam = newExpanded ? panel : false;
        if (expandedParam) {
          handleSetIsSubmenuClose(false);
        }
        handleSetExpanded(expandedParam);
        if (!link) return;
        if (!panel) {
          changeScrollTarget(null);
          if (currentPath.includes(link)) {
            handleSetShouldOpenMenu(false);
          }
          if (router) {
            router.push(link);
          } else {
            window.location.href = link;
          }
        }
      } else {
        handleSetNextSubmenu(panel);
      }
    };

  useEffect(() => {
    if (!state.nextSubmenu) return;
    handleSetExpanded(false);
  }, [state.nextSubmenu]);

  useEffect(() => {
    if (!state.isSubmenuClose || !state.nextSubmenu) return;
    handleOpenNextSubmenu(state.nextSubmenu);
  }, [state.isSubmenuClose]);

  const handleMenuButton = useCallback(() => {
    handleSetShouldOpenMenu(!state.shouldOpenMenu);
  }, [state.shouldOpenMenu]);

  const handleOnExited = useCallback(() => {
    changeScrollTarget(null);
    handleSetInitialState();
  }, []);
  const handleOnEnter = useCallback(() => {
    changeScrollTarget(AccordionWrapperRef.current);
  }, [AccordionWrapperRef.current]);

  useEffect(() => {
    if (!isMobile) handleOnExited();
  }, [isMobile]);
  return (
    <>
      <ImitateHeaderHeightStack />
      <HeaderSection
        width="normal"
        borderBottomOn
        sx={{ backgroundColor: backgroundColor }}>
        <StyledStack>
          <StyledLogoLink to="/">
            <StyledLogo
              alt="mobileRumblefishLogo"
              src={useSolidColorLogo ? solidLogo : gradientLogo}
            />
          </StyledLogoLink>

          <OptionsStack>
            <MobileMenuButton
              onClick={handleMenuButton}
              isOpen={!state.shouldOpenMenu}
            />
          </OptionsStack>
        </StyledStack>

        <StyledMobileMenuCollapse
          onExited={handleOnExited}
          onEntered={handleOnEnter}
          in={state.shouldOpenMenu}
          timeout={350}
          unmountOnExit>
          <MobileMenuBorderStack>
            <MobileMenuContentStack>
              <StyledAccordionWrapper ref={AccordionWrapperRef}>
                <MobileAccordionElement
                  handleCloseAnimEnd={handleCloseAnimEnd}
                  name={'Services'}
                  currentExpanded={state.expanded}
                  onChange={handleChange}
                  isActive={currentPath.startsWith('/services')}>
                  <ServicesSubmenu />
                </MobileAccordionElement>
                <MobileAccordionElement
                  handleCloseAnimEnd={handleCloseAnimEnd}
                  name={'Products'}
                  currentExpanded={state.expanded}
                  onChange={handleChange}
                  isActive={currentPath.startsWith('/products')}>
                  <ProductsSubmenu />
                </MobileAccordionElement>
                <MobileAccordionElement
                  handleCloseAnimEnd={handleCloseAnimEnd}
                  nonExpandable
                  name={'Case studies'}
                  linkTo="/case-studies"
                  currentExpanded={state.expanded}
                  onChange={handleChange}
                  isActive={currentPath === '/case-studies'}
                />
                <MobileAccordionElement
                  handleCloseAnimEnd={handleCloseAnimEnd}
                  nonExpandable
                  name={'Careers'}
                  linkTo="/careers"
                  currentExpanded={state.expanded}
                  onChange={handleChange}
                  isActive={currentPath === '/careers'}
                />
                <MobileAccordionElement
                  handleCloseAnimEnd={handleCloseAnimEnd}
                  name={'Blog'}
                  currentExpanded={state.expanded}
                  onChange={handleChange}
                  isActive={currentPath.startsWith('/blog')}>
                  <BlogSubmenu blogPosts={blogPosts} />
                </MobileAccordionElement>
                <MobileAccordionElement
                  handleCloseAnimEnd={handleCloseAnimEnd}
                  nonExpandable
                  name={'About us'}
                  linkTo="/team"
                  currentExpanded={state.expanded}
                  onChange={handleChange}
                  isActive={currentPath === '/team'}
                />
                <MobileAccordionElement
                  handleCloseAnimEnd={handleCloseAnimEnd}
                  nonExpandable
                  name={'Contact'}
                  linkTo="/contact"
                  currentExpanded={state.expanded}
                  onChange={handleChange}
                  isActive={currentPath === '/contact'}
                />
              </StyledAccordionWrapper>
            </MobileMenuContentStack>
          </MobileMenuBorderStack>
        </StyledMobileMenuCollapse>
      </HeaderSection>
    </>
  );
};

export const Header = memo(
  ({
    blogPosts,
    backgroundColor,
    useSolidColorLogo,
  }: HeaderProps): ReactJSXElement => {
    const isMobile = useMediaQuery(
      `(max-width:${breakpoints.values?.md || 960}px)`,
      {
        noSsr: true,
      }
    );
    return (
      <>
        <Box display={{ md: 'none' }}>
          <MobileHeader
            isMobile={isMobile}
            useSolidColorLogo={useSolidColorLogo}
            backgroundColor={backgroundColor}
            blogPosts={blogPosts}
          />
        </Box>
        <Box display={{ xs: 'none', md: 'block' }}>
          <DesktopHeader
            isMobile={isMobile}
            useSolidColorLogo={useSolidColorLogo}
            backgroundColor={backgroundColor}
            blogPosts={blogPosts}
          />
        </Box>
      </>
    );
  }
);

Header.displayName = 'Header';
