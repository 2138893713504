import axios from 'axios';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  extractKeysFromFields,
  generateDefaultState,
  validateFields,
} from './helpers';
import type {
  IFieldsData,
  IReturnedState,
  ISendData,
  TFormsKeys,
  TStateCheckbox,
  TStateInput,
} from './types';

export const useForm = <Type extends TFormsKeys>(
  fields: IFieldsData<Type>,
  fileRequired: boolean,
  submitUrl: string,
  useCaptcha: boolean,
  callback?: () => void,
  onSubmitError?: () => void
) => {
  const defaultState: IReturnedState<Type> = generateDefaultState(
    fields as IFieldsData<TFormsKeys>
  );
  const dispatch = useDispatch();

  const [formData, setData] = useState(defaultState);
  const [loading, setLoading] = useState<boolean>(false);
  const [captcha, setCaptcha] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [fileBase64, setFileBase64] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);

  type TInputFieldEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
  type TFileFieldEvent = ChangeEvent<HTMLInputElement>;

  const setInputField = (field: Type['inputs'], e: TInputFieldEvent) => {
    const newInputValue: TStateInput = {
      text: e.target.value,
      errorMessage: formData[field].errorMessage,
    };

    setData({
      ...formData,
      [field]: newInputValue,
    });
  };

  const setCheckBoxField = (field: Type['checkboxes']) => {
    const newCheckboxValue: TStateCheckbox = {
      checked: !formData[field].checked,
      isError: formData[field].isError,
    };

    setData({
      ...formData,
      [field]: newCheckboxValue,
    });
  };

  const setFileField = (e: TFileFieldEvent) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Validate file type and size
      const validFileTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB

      if (!validFileTypes.includes(file.type)) {
        setFileError(
          'Invalid file type. Please upload a PDF or Word document.'
        );
        return;
      }

      if (file.size > maxSizeInBytes) {
        setFileError('File size exceeds the maximum limit of 2 MB.');
        return;
      }

      setFile(file);
      setFileError(null);

      const reader = new FileReader();
      reader.onloadend = () => {
        setFileBase64(reader.result as string);
      };
      reader.onerror = () => {
        setFileError('Error reading file. Please try again.');
      };
      reader.readAsDataURL(file);
    }
  };

  const isFormInvalid = (): boolean => {
    let isError = false;
    const newFormData: IReturnedState<TFormsKeys> = {
      ...formData,
    } as IReturnedState<TFormsKeys>;

    const keys = extractKeysFromFields(fields as IFieldsData<TFormsKeys>);

    isError = validateFields(
      keys,
      newFormData,
      fields as IFieldsData<TFormsKeys>
    );
    setData(newFormData);

    return isError;
  };

  const isFileInvalid = (): boolean => {
    if (!fileRequired) return false;
    if (!file && !fileError) {
      setFileError('CV is required.');
    }
    return !file && !fileError;
  };

  const submitForm = (data: ISendData) => {
    const isFormValid = !isFormInvalid();
    const isCaptchaValid = !(
      useCaptcha &&
      (captcha === null || captcha === '')
    );
    const isFileValid = !isFileInvalid();

    if (isFormValid && isCaptchaValid && isFileValid) {
      setLoading(true);

      const dataWithCaptcha =
        captcha && typeof captcha === 'string'
          ? {
              ...data,
              captcha,
            }
          : data;

      const dataWithAttachment = fileBase64
        ? {
            ...dataWithCaptcha,
            attachment: fileBase64,
          }
        : dataWithCaptcha;

      axios
        .post(submitUrl, JSON.stringify(dataWithAttachment, null, 2), {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          setTimeout(() => {
            setLoading(false);
            setData(defaultState);
            setFile(null);
            setFileBase64(null);
            if (callback) callback();
          }, 1000);
        })
        .catch((e) => {
          if (onSubmitError) {
            onSubmitError();
          }

          setLoading(false);
          dispatch({ type: 'SET_ERROR_ASYNC', e });
        });
    }
  };

  const methods = {
    setInputField,
    setCheckBoxField,
    setFileField,
    submitForm,
    setCaptcha,
  };

  return {
    data: formData,
    methods,
    loading,
    file,
    fileError,
  };
};
