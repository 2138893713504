import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import marekImg from '../../assets/webp/fromPNG/img_marek_1x.webp';
import { Section } from '../Section';

const PREFIX = 'FounderCto';

const classes = {
  founderCto: `${PREFIX}-founderCto`,
  founderCtoImageContainer: `${PREFIX}-founderCtoImageContainer`,
  founderCtoCopy: `${PREFIX}-founderCtoCopy`,
};

const StyledSection = styled('div')(({ theme: { spacing, breakpoints } }) => ({
  [`& .${classes.founderCto}`]: {
    display: 'grid',
    gap: spacing(2),
    gridTemplateColumns: '1fr 1fr',

    [breakpoints.only('xs')]: {
      gridTemplateColumns: '1fr',
      gap: spacing(5),
    },
  },

  [`& .${classes.founderCtoImageContainer}`]: {
    display: 'grid',
    alignContent: 'start',
    justifyContent: 'center',
    gap: spacing(4),

    ['& img']: {
      width: '100%',
      height: 'auto',
    },
    [breakpoints.only('xs')]: {
      textAlign: 'center',
      gap: spacing(5),
    },
  },

  [`& .${classes.founderCtoCopy}`]: {
    display: 'grid',
    alignItems: 'center',
    alignContent: 'center',
    gap: spacing(1),
    '& > h2': {
      marginBottom: '1rem',
    },
  },
}));

export const FounderCto: React.FC = () => {
  return (
    <Section theme="gray">
      <StyledSection>
        <div className={classes.founderCto}>
          <div className={classes.founderCtoImageContainer}>
            <img
              width={400}
              height={512}
              alt="CTO and Founder Marek Kowalski"
              src={marekImg}
            />
          </div>

          <div className={classes.founderCtoCopy}>
            <Typography variant="caption">— Founder & CTO</Typography>
            <Typography variant="h2">Marek Kowalski</Typography>
            <Typography variant="body1">
              Marek is a Chief Technology Officer at Rumble Fish Software
              Development. He has nearly 20 years of experience in leading the
              most innovative and cutting edge IT projects for global customers.
              Marek stands at the forefront of Blockchain technology, being one
              of the first engineers worldwide to bring its advanced concepts to
              life. He’s led Blockchain projects for companies like MakerDAO,
              building decentralized cryptocurrency exchange platforms and token
              trading apps. One of his roles as a CTO is to share his extensive
              Blockchain expertise. He created an entire training hub for
              developers who wish to learn Blockchain and use it in real-life
              Rumble Fish projects. As a very involved mentor, he cares deeply
              about guiding them to learn, grow and become fully independent
              Blockchain experts.
            </Typography>
          </div>
        </div>
      </StyledSection>
    </Section>
  );
};
