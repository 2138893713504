import React from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import bash from 'react-syntax-highlighter/dist/cjs/languages/hljs/bash';
import javascript from 'react-syntax-highlighter/dist/cjs/languages/hljs/javascript';
import json from 'react-syntax-highlighter/dist/cjs/languages/hljs/json';
import kotlin from 'react-syntax-highlighter/dist/cjs/languages/hljs/kotlin';
import yaml from 'react-syntax-highlighter/dist/cjs/languages/hljs/yaml';
import solidity from 'react-syntax-highlighter/dist/cjs/languages/prism/solidity';
import { default as SyntaxHighlighterPrism } from 'react-syntax-highlighter/dist/cjs/prism-light';
import docco from 'react-syntax-highlighter/dist/cjs/styles/hljs/docco';
import prism from 'react-syntax-highlighter/dist/cjs/styles/prism/prism';
import { colors } from '../../../theme/default/color';
import type {
  ContentTypesConfigurationKeys,
  IReplace,
  RichTextProps,
  ComponentGroupEmbeddedKeys,
  EmbeddedEntries,
} from '../RichText.types';
import ActionButton from './ActionButton';
import Anchor from './Anchor';
import CaseStudyTestimonial from './CaseStudyTestimonial';
import CaseStudyTestimonialInline from './CaseStudyTestimonialInline';
import CurrentOpenings from './CurrentOpenings';
import DownloadButton from './DownloadButton';
import ExpandableImage from './ExpandableImage';
import Headline from './Headline';
import HubspotForm from './HubspotForm';
import IconCardsCollection from './IconCardsCollection';
import Image from './Image';
import ImagesGrid from './ImagesGrid';
import ImagesSlider from './ImagesSlider';
import InstagramPosts from './InstagramPosts';
import LinkImage from './LinkImage';
import PersonCard from './PersonCard';
import TextBlockGrid from './TextBlockGrid';
import TilesCollection from './TilesCollection';
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { contentTypesConfiguration } from '../RichText.types';

import { Box, Theme, Typography, styled } from '@mui/material';
import pluralize from 'pluralize';
import {
  CustomActionButtonEntity,
  CustomAnchorEntity,
  CustomCaseStudyTestimonialEntity,
  CustomCurrentOpeningEntity,
  CustomDownloadButtonEntity,
  CustomHubspotFormEntity,
  CustomIconCardsCollectionEntity,
  CustomImageEntity,
  CustomImageSliderEntity,
  CustomImagesGridEntity,
  CustomInstagramPostEntity,
  CustomLinkImageEntity,
  CustomPersonCardEntity,
  CustomTextBlockGridEntity,
  CustomTilesCollectionEntity,
} from '../../../customStrapiTypes';
import clsx from 'clsx';

const PREFIX = 'RichTextGlobal';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    color: theme.palette.primary.main,
    padding: '0 20px 0 20px',
    '& pre': {
      fontFamily: 'Consolas,monospace',
      display: 'block',
      backgroundColor: colors.black,
      height: 'auto',
      overflow: 'auto',
      margin: '20px 0px',
      color: colors.white,
      fontSize: '14px',
      borderRadius: '5px',
      '& a': {
        color: colors.white,
      },
    },
    '& h3': {
      fontSize: '22px',
      fontWeight: 'bold',
    },
    '& h4': {
      color: colors.black,
      position: 'relative !important',
      display: 'table',
      textAlign: 'left',
      fontSize: '26px',
      fontWeight: 'bold',
      marginBottom: '40px',
      marginRight: 'auto',
      '&:after': {
        content: "''",
        height: '3px',
        width: '40px',
        background: colors.blue,
        position: 'absolute',
        left: '20px',
        bottom: '-5px',
      },
    },
    '& ul': {
      fontSize: '16px',
      textAlign: 'left',
      '&.UnorderedDefaultList': {
        '& li': {
          '&:before': {
            content: '""',
            display: 'inline-block',
            width: '9px',
            top: '-2px',
            position: 'relative',
            height: '9px',
            borderRadius: '5px',
            marginRight: '10px',
            background: theme.palette.secondary.main,
          },
        },
      },
      '&.UnorderedDashedList': {
        '& li': {
          '&:before': {
            content: '""',
            display: 'inline-block',
            width: '5px',
            marginBottom: '4px',
            position: 'relative',
            height: '1.5px',
            marginRight: '10px',
            background: theme.palette.primary.main,
          },
        },
      },
      '& li': {
        fontSize: '16px',
        listStyle: 'none',
        '& fragment>ul>li': {
          '&:before': {
            content: '"unset !important"',
          },
        },
        '& p': {
          display: 'inline',
        },
      },
    },

    '& ol': {
      '& li::marker': {
        fontWeight: 'bold',
      },
      fontSize: '16px',
      textAlign: 'left',
      '& li': {
        fontSize: '16px',
        '& fragment>ul>li': {
          '&:before': {
            content: '"unset !important"',
          },
        },
        '& p': {
          display: 'inline',
        },
      },
    },
    '& code': {
      display: 'block',
      padding: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
}));

SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('yaml', yaml);
SyntaxHighlighterPrism.registerLanguage('solidity', solidity);
SyntaxHighlighter.registerLanguage('kotlin', kotlin);

export const formatToCompare = (str: string): string | undefined => {
  if (!str) return;
  return str.replace(/\s+/g, '').toLowerCase();
};
export const RichTextGlobal: React.FC<RichTextProps> = ({
  content,
  embeddedItems,
}) => {
  const websiteUrl = process.env.NEXT_PUBLIC_PATH || '';
  const options = {
    replace: ({ name, children, attribs }: IReplace) => {
      if (name === 'h1') {
        return (
          <Typography variant="h1">{domToReact(children, options)}</Typography>
        );
      }
      if (name === 'h2') {
        if (children[0]?.name === 'u') {
          return (
            <Headline
              headline={domToReact(children[0].children, options) as string}
            />
          );
        } else {
          return (
            <Typography variant="h2">
              {domToReact(children, options)}
            </Typography>
          );
        }
      }
      if (name === 'h3') {
        return (
          <Typography variant="h3">{domToReact(children, options)}</Typography>
        );
      }
      if (name === 'h4') {
        return (
          <Typography variant="h4">{domToReact(children, options)}</Typography>
        );
      }
      if (name == 'img') {
        return (
          <Box
            component="span"
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              img: {
                width: { xs: '100%' },
              },
            }}>
            <img src={attribs?.src} />
          </Box>
        );
      }
      if (name === 'a') {
        return (
          <a
            style={{ color: '#3062D4' }}
            href={attribs?.href}
            target={`${
              attribs?.href?.startsWith(websiteUrl) ? '_self' : '_blank'
            }`}
            rel={`${
              attribs?.href?.startsWith(websiteUrl) ? '' : 'noopener noreferrer'
            }`}>
            {domToReact(children, options)}
          </a>
        );
      }
      if (name === 'figure') {
        type elType = { name: string };
        const imageSrc = children.find((el: elType) => el.name === 'img')
          ?.attribs?.src;
        const captionElem = children.find(
          (el: elType) => el.name === 'figcaption'
        )?.children[0];
        if (!imageSrc) return null;
        if (captionElem?.data) {
          return <ExpandableImage caption={captionElem.data} src={imageSrc} />;
        } else if (captionElem?.attribs) {
          const linkTo = captionElem?.attribs?.href;
          const caption = captionElem?.children[0]?.data;
          return (
            <ExpandableImage caption={caption} linkTo={linkTo} src={imageSrc} />
          );
        }
      }
      if (name === 'ul') {
        const isDashed = attribs?.style === 'list-style-type:circle;';
        return (
          <ul
            className={clsx({
              UnorderedDashedList: isDashed,
              UnorderedDefaultList: !isDashed,
            })}>
            {domToReact(children, options)}
          </ul>
        );
      }
      if (name === 'li') {
        return (
          <Typography component={'li'} variant="body1">
            {domToReact(children, options)}
          </Typography>
        );
      }
      if (name === 'p') {
        const regex = /^{{.*}}$/;
        const isEmbedded = regex.test(children[0]?.data);

        if (isEmbedded) {
          if (!embeddedItems) return;
          const [type, contentTypeName] = children[0].data
            .slice(2, -2)
            .split(',');

          const formattedType = pluralize(type)
            .replace(/([a-z])([A-Z])/g, '$1_$2')
            .toLowerCase();

          const oneTypeItems = embeddedItems[
            formattedType as ComponentGroupEmbeddedKeys
          ]?.data as unknown as { attributes: EmbeddedEntries }[] | undefined;

          const currentEmbeddedItem = oneTypeItems?.find((x) => {
            return (
              formatToCompare(
                x.attributes?.[
                  contentTypesConfiguration[
                    type as ContentTypesConfigurationKeys
                  ]?.field as keyof EmbeddedEntries
                ]
              ) === formatToCompare(contentTypeName)
            );
          }) as {
            attributes: {
              language: string;
              body: string;
              style: string;
            };
          };

          if (!currentEmbeddedItem) return;

          if (type === 'codeSnippet') {
            return (
              <SyntaxHighlighter
                language={currentEmbeddedItem.attributes.language}
                style={
                  currentEmbeddedItem.attributes.language !== 'solidity'
                    ? docco
                    : prism
                }>
                {currentEmbeddedItem.attributes.body}
              </SyntaxHighlighter>
            );
          } else if (type === 'caseStudyTestimonial') {
            if (currentEmbeddedItem.attributes.style === 'normal') {
              return (
                <CaseStudyTestimonial
                  model={
                    currentEmbeddedItem as unknown as CustomCaseStudyTestimonialEntity
                  }
                />
              );
            } else {
              return (
                <CaseStudyTestimonialInline
                  model={
                    currentEmbeddedItem as unknown as CustomCaseStudyTestimonialEntity
                  }
                />
              );
            }
          } else if (type === 'imagesGrid') {
            return (
              <ImagesGrid
                model={currentEmbeddedItem as CustomImagesGridEntity}
              />
            );
          } else if (type === 'textBlockGrid') {
            return (
              <TextBlockGrid
                model={currentEmbeddedItem as CustomTextBlockGridEntity}
              />
            );
          } else if (type === 'downloadButton') {
            return (
              <DownloadButton
                model={currentEmbeddedItem as CustomDownloadButtonEntity}
              />
            );
          } else if (type === 'hubspotForm') {
            return (
              <HubspotForm
                model={
                  currentEmbeddedItem as unknown as CustomHubspotFormEntity
                }
              />
            );
          } else if (type === 'actionButton') {
            return (
              <ActionButton
                model={
                  currentEmbeddedItem as unknown as CustomActionButtonEntity
                }
              />
            );
          } else if (type === 'imageSlider') {
            return (
              <ImagesSlider
                model={currentEmbeddedItem as CustomImageSliderEntity}
              />
            );
          }
          if (type === 'instagramPosts') {
            return (
              <InstagramPosts
                model={currentEmbeddedItem as CustomInstagramPostEntity}
              />
            );
          }
          if (type === 'image') {
            return (
              <Image
                model={currentEmbeddedItem as unknown as CustomImageEntity}
              />
            );
          }
          if (type === 'iconCardsCollection') {
            return (
              <IconCardsCollection
                model={
                  currentEmbeddedItem as unknown as CustomIconCardsCollectionEntity
                }
              />
            );
          }
          if (type === 'anchor') {
            return (
              <Anchor
                model={currentEmbeddedItem as unknown as CustomAnchorEntity}
              />
            );
          }
          if (type === 'personCard') {
            return (
              <PersonCard
                model={currentEmbeddedItem as unknown as CustomPersonCardEntity}
              />
            );
          }
          if (type === 'currentOpenings') {
            return (
              <CurrentOpenings
                model={currentEmbeddedItem as CustomCurrentOpeningEntity}
              />
            );
          }
          if (type === 'linkImage') {
            return (
              <LinkImage
                model={currentEmbeddedItem as unknown as CustomLinkImageEntity}
              />
            );
          }
          if (type === 'tilesCollection') {
            return (
              <TilesCollection
                model={currentEmbeddedItem as CustomTilesCollectionEntity}
              />
            );
          }
        } else {
          if (children[0]?.name === 'img') {
            const imageElement = children[0];

            return <ExpandableImage src={imageElement.attribs.src} />;
          } else {
            return (
              <Typography component="p" variant="body1">
                {domToReact(children, options)}
              </Typography>
            );
          }
        }
      }
    },
  } as HTMLReactParserOptions;

  return <Root className={classes.root}>{parse(content, options)}</Root>;
};
