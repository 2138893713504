import { Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { Checkbox, InputField } from '@rumblefish/ui/AlgeaTheme';
import React, { useEffect, useRef, useState } from 'react';
import type ReCAPTCHA from 'react-google-recaptcha';
import LoadingIcon from '../../../assets/svg/AlgeaTheme/loading.svg';
import type { ISendData } from '../../../hooks/useForm/types';
import { useForm } from '../../../hooks/useForm/useForm';
import { isDarkOrNavy } from '../../../utils/darkOrNavy';
import AlertSnackbar from '../../AlertSnackbar';
import type { ContactFormProps } from './ContactForm.types';
import { contactFormFields } from './FieldsData';
import {
  StyledEmailLinkA,
  StyledLoadingIcon,
  StyledStack,
  StyledButtonWrapper,
  StyledTextWrapper,
  StyledEmailLinkText,
  StyledButton,
  StyledRecaptcha,
} from './styles';

const LinkLabel = () => {
  const theme = useTheme();
  return (
    <>
      I accept Rumble Fish{' '}
      <Link
        href="/privacy-policy"
        underline="always"
        color={isDarkOrNavy(theme) ? '#ffffff' : '#2F57F4'}
        target="_blank">
        Privacy Policy
      </Link>
    </>
  );
};

export const ContactForm = ({ error, ...props }: ContactFormProps) => {
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(!!error?.messages);

  useEffect(() => {
    setIsErrorOpen(!!error?.messages);
  }, [error?.messages]);

  const { data, methods, loading } = useForm(
    contactFormFields,
    false,
    `${process.env.BACKEND_API}/v1/contact`,
    true,
    () => captchaRef.current?.reset()
  );

  const dataToSend: ISendData = {
    fullName: data.fullName.text,
    email: data.email.text,
    subject: 'Contact Form',
    message: data.message.text,
  };

  return (
    <StyledStack direction="column" justifyContent="space-between" {...props}>
      <Box height="304px" sx={{ zIndex: 1 }}>
        <Stack spacing={4} marginTop="24px" height="100%">
          <InputField
            size="small"
            label="Full Name"
            onChangeEvent={(e) => methods.setInputField('fullName', e)}
            value={data.fullName.text}
            disabled={loading}
            errorMessage={data.fullName.errorMessage}
            variant="filled"
            inputId={contactFormFields.fullName.id}
          />
          <InputField
            size="small"
            label="Bussiness Email"
            onChangeEvent={(e) => methods.setInputField('email', e)}
            value={data.email.text}
            disabled={loading}
            errorMessage={data.email.errorMessage}
            variant="filled"
            inputId={contactFormFields.email.id}
          />
          <InputField
            size="small"
            label="Message"
            onChangeEvent={(e) => methods.setInputField('message', e)}
            value={data.message.text}
            disabled={loading}
            errorMessage={data.message.errorMessage}
            variant="filled"
            inputId={contactFormFields.message.id}
          />
        </Stack>
        <Checkbox
          label={<LinkLabel />}
          onChangeEvent={() => methods.setCheckBoxField('policy')}
          value={data.policy.checked}
          disabled={loading}
          error={data.policy.isError}
          checkboxId={contactFormFields.policy.id}
        />
        <StyledRecaptcha
          sitekey={process.env.CAPTCHA_KEY as string}
          onChange={(value) => methods.setCaptcha(value)}
          ref={captchaRef}
        />
      </Box>
      <StyledButtonWrapper>
        <StyledButton
          onClick={() => methods.submitForm(dataToSend)}
          variant="contained"
          size="large">
          {loading ? (
            <StyledLoadingIcon src={LoadingIcon} alt="Loading..." />
          ) : (
            'Send your message'
          )}
        </StyledButton>
        {!props.hideBottomText && (
          <StyledTextWrapper>
            <Typography variant="bodySmall">
              Or if you tired of filling forms email us.{' '}
            </Typography>
            <StyledEmailLinkA href="mailto:hello@rumblefish.com">
              <StyledEmailLinkText variant="bodySmall">
                hello@rumblefish.com
              </StyledEmailLinkText>
            </StyledEmailLinkA>
          </StyledTextWrapper>
        )}
      </StyledButtonWrapper>
      {error?.messages.map((msg, idx) => (
        <AlertSnackbar
          key={idx}
          openErrorMessage={isErrorOpen}
          setOpenErrorMessage={setIsErrorOpen}
          errorMessage={msg}
        />
      ))}
    </StyledStack>
  );
};
