import React, { useCallback } from 'react';
import type { ButtonProps } from './Button.types';
import {
  ArrowLeft,
  ArrowRight,
  StyledButton,
  StyledSecondaryButton,
} from './styles';
import { Stack, Typography } from '@mui/material';
import { useRouterEventually } from '../../../hooks';

// TODO: refactor to use next Link instead of onClick
export const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  size = 'medium',
  iconDirection = 'right',
  typographyVariant = 'strong_300',
  link,
  secondaryVariant = false,
  shadowColor,
  ...props
}) => {
  const shouldHaveText = variant !== 'flat';
  const shouldHaveArrow = variant === 'flat' || variant === 'link';
  const Arrow = iconDirection === 'right' ? ArrowRight : ArrowLeft;

  const router = useRouterEventually();
  const handleClick = useCallback(
    (link: string | undefined) => {
      if (!link) return;
      if (router) {
        router.push(link);
      } else {
        window.location.href = link;
      }
    },
    [router]
  );

  const Wrapper = ({ children }: { children: React.ReactNode }) =>
    secondaryVariant ? (
      <StyledSecondaryButton
        disableRipple
        variant={variant}
        size={size}
        shadowColor={shadowColor}
        {...props}>
        {children}
      </StyledSecondaryButton>
    ) : (
      <StyledButton disableRipple variant={variant} size={size} {...props}>
        {children}
      </StyledButton>
    );

  return (
    <Wrapper>
      <Stack onClick={() => handleClick(link)} color={'inherit'}>
        {variant === 'icon' ? (
          <>{children}</>
        ) : (
          <>
            {shouldHaveText && (
              <Typography
                variant={typographyVariant}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: 'inherit',
                }}>
                {children}
              </Typography>
            )}
            {shouldHaveArrow && <Arrow />}
          </>
        )}
      </Stack>
    </Wrapper>
  );
};
