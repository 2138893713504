import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';

const SvgPath = ({
  iconType,
  shapeId,
  solidColor,
}: SvgPathProps): ReactJSXElement => {
  if (iconType === 'bold') {
    return (
      <path
        d="M 93.5 170.101562 C 92.601562 170.101562 91.699219 169.816406 90.941406 169.246094 L 87.683594 166.804688 C 68.746094 152.648438 29.335938 123.175781 11.054688 89.359375 C -0.0195312 68.675781 2.371094 46.894531 17.453125 31.054688 C 26.089844 22.039062 37.554688 17.078125 49.753906 17.078125 C 49.761719 17.078125 49.777344 17.078125 49.785156 17.078125 C 61.972656 17.085938 73.421875 22.046875 82.015625 31.054688 L 93.515625 43.089844 L 104.980469 31.054688 C 113.578125 22.046875 125.03125 17.085938 137.21875 17.078125 C 137.226562 17.078125 137.238281 17.078125 137.246094 17.078125 C 149.449219 17.078125 160.914062 22.039062 169.539062 31.046875 C 184.628906 46.894531 187.023438 68.675781 175.953125 89.347656 C 157.71875 123.144531 118.308594 152.613281 99.367188 166.769531 L 96.054688 169.246094 C 95.300781 169.816406 94.398438 170.101562 93.5 170.101562 Z M 49.753906 25.578125 C 39.898438 25.578125 30.609375 29.605469 23.605469 36.925781 C 11.1875 49.964844 9.292969 68.058594 18.539062 85.324219 C 35.925781 117.492188 74.328125 146.199219 92.777344 160 L 93.5 160.542969 L 94.28125 159.957031 C 112.730469 146.164062 151.125 117.457031 168.460938 85.316406 C 177.707031 68.058594 175.816406 49.964844 163.390625 36.917969 C 156.390625 29.605469 147.109375 25.578125 137.246094 25.578125 C 137.238281 25.578125 137.230469 25.578125 137.226562 25.578125 C 127.375 25.585938 118.105469 29.613281 111.136719 36.925781 L 96.597656 52.175781 C 95.796875 53.023438 94.683594 53.496094 93.523438 53.496094 C 92.359375 53.496094 91.246094 53.023438 90.445312 52.183594 L 75.863281 36.925781 C 68.894531 29.613281 59.628906 25.585938 49.777344 25.578125 C 49.769531 25.578125 49.761719 25.578125 49.753906 25.578125 "
        id={shapeId}
        fill={solidColor ? 'currentColor' : 'white'}
        fillRule="nonzero"
        fillOpacity="1"
        strokeOpacity={1}
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  } else if (iconType === 'normal') {
    return (
      <path
        d="M 93.5 170.101562 C 92.601562 170.101562 91.699219 169.816406 90.941406 169.246094 L 87.683594 166.804688 C 68.746094 152.648438 29.335938 123.175781 11.054688 89.359375 C -0.0195312 68.675781 2.371094 46.894531 17.453125 31.054688 C 26.089844 22.039062 37.554688 17.078125 49.753906 17.078125 C 49.761719 17.078125 49.777344 17.078125 49.785156 17.078125 C 61.972656 17.085938 73.421875 22.046875 82.015625 31.054688 L 93.515625 43.089844 L 104.980469 31.054688 C 113.578125 22.046875 125.03125 17.085938 137.21875 17.078125 C 137.226562 17.078125 137.238281 17.078125 137.246094 17.078125 C 149.449219 17.078125 160.914062 22.039062 169.539062 31.046875 C 184.628906 46.894531 187.023438 68.675781 175.953125 89.347656 C 157.71875 123.144531 118.308594 152.613281 99.367188 166.769531 L 96.054688 169.246094 C 95.300781 169.816406 94.398438 170.101562 93.5 170.101562 Z M 49.753906 25.578125 C 39.898438 25.578125 30.609375 29.605469 23.605469 36.925781 C 11.1875 49.964844 9.292969 68.058594 18.539062 85.324219 C 35.925781 117.492188 74.328125 146.199219 92.777344 160 L 93.5 160.542969 L 94.28125 159.957031 C 112.730469 146.164062 151.125 117.457031 168.460938 85.316406 C 177.707031 68.058594 175.816406 49.964844 163.390625 36.917969 C 156.390625 29.605469 147.109375 25.578125 137.246094 25.578125 C 137.238281 25.578125 137.230469 25.578125 137.226562 25.578125 C 127.375 25.585938 118.105469 29.613281 111.136719 36.925781 L 96.597656 52.175781 C 95.796875 53.023438 94.683594 53.496094 93.523438 53.496094 C 92.359375 53.496094 91.246094 53.023438 90.445312 52.183594 L 75.863281 36.925781 C 68.894531 29.613281 59.628906 25.585938 49.777344 25.578125 C 49.769531 25.578125 49.761719 25.578125 49.753906 25.578125 "
        id={shapeId}
        fill={solidColor ? 'currentColor' : 'white'}
        fillRule="nonzero"
        fillOpacity="1"
        strokeOpacity={1}
        strokeWidth="1.1"
        stroke={solidColor ? 'currentColor' : ''}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  } else {
    return (
      <path
        d="M 93.5 170.101562 C 92.601562 170.101562 91.699219 169.816406 90.941406 169.246094 L 87.683594 166.804688 C 68.746094 152.648438 29.335938 123.175781 11.054688 89.359375 C -0.0195312 68.675781 2.371094 46.894531 17.453125 31.054688 C 26.089844 22.039062 37.554688 17.078125 49.753906 17.078125 C 49.761719 17.078125 49.777344 17.078125 49.785156 17.078125 C 61.972656 17.085938 73.421875 22.046875 82.015625 31.054688 L 93.515625 43.089844 L 104.980469 31.054688 C 113.578125 22.046875 125.03125 17.085938 137.21875 17.078125 C 137.226562 17.078125 137.238281 17.078125 137.246094 17.078125 C 149.449219 17.078125 160.914062 22.039062 169.539062 31.046875 C 184.628906 46.894531 187.023438 68.675781 175.953125 89.347656 C 157.71875 123.144531 118.308594 152.613281 99.367188 166.769531 L 96.054688 169.246094 C 95.300781 169.816406 94.398438 170.101562 93.5 170.101562 Z M 49.753906 25.578125 C 39.898438 25.578125 30.609375 29.605469 23.605469 36.925781 C 11.1875 49.964844 9.292969 68.058594 18.539062 85.324219 C 35.925781 117.492188 74.328125 146.199219 92.777344 160 L 93.5 160.542969 L 94.28125 159.957031 C 112.730469 146.164062 151.125 117.457031 168.460938 85.316406 C 177.707031 68.058594 175.816406 49.964844 163.390625 36.917969 C 156.390625 29.605469 147.109375 25.578125 137.246094 25.578125 C 137.238281 25.578125 137.230469 25.578125 137.226562 25.578125 C 127.375 25.585938 118.105469 29.613281 111.136719 36.925781 L 96.597656 52.175781 C 95.796875 53.023438 94.683594 53.496094 93.523438 53.496094 C 92.359375 53.496094 91.246094 53.023438 90.445312 52.183594 L 75.863281 36.925781 C 68.894531 29.613281 59.628906 25.585938 49.777344 25.578125 C 49.769531 25.578125 49.761719 25.578125 49.753906 25.578125 "
        id={shapeId}
        fill={solidColor ? 'currentColor' : 'white'}
        fillRule="nonzero"
        fillOpacity="1"
        strokeOpacity={1}
        strokeWidth="1"
        stroke={solidColor ? 'currentColor' : ''}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    );
  }
};

export const PassionIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_passionCurrentColorGradient`;
  const newColorId = `${prefix}_passionNewColorGradient`;
  const shapeId = `${prefix}_passionShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.tealColors?.PLUS_2,
    },
  };
  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.tealColors?.PLUS_3,
      stop: theme.palette.tealColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox={'-20 -20 220 220'}>
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>

          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};
