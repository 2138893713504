import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import type { CustomSvgIconsProps, SvgPathProps } from './CustomSvgIcons.types';
import { viewBoxes } from './CustomSvgIcons.types';

const SvgPath = ({
  iconType,
  shapeId,
  solidColor,
}: SvgPathProps): ReactJSXElement => {
  if (iconType === 'bold') {
    return (
      <path
        d="M12 17.0798V20.9998M12 17.0798C10.9098 17.0798 9.84407 16.7565 8.937 16.1518C8.7375 16.0186 8.49328 15.97 8.258 16.0168L5.412 16.5868C4.73183 16.7227 4.11979 17.0902 3.68003 17.6266C3.24026 18.163 2.99996 18.8352 3 19.5288V20.9998M12 17.0798C13.0902 17.0798 14.1559 16.7565 15.063 16.1518C15.162 16.0859 15.273 16.0401 15.3897 16.0171C15.5063 15.9941 15.6264 15.9944 15.743 16.0178L18.588 16.5868C19.2682 16.7227 19.8802 17.0902 20.32 17.6266C20.7597 18.163 21 18.8352 21 19.5288V20.9998M16 7.3978V9.9998C16 11.0607 15.5786 12.0781 14.8284 12.8282C14.0783 13.5784 13.0609 13.9998 12 13.9998C10.9391 13.9998 9.92172 13.5784 9.17157 12.8282C8.42143 12.0781 8 11.0607 8 9.9998V7.3978M14.101 13.4048L14.825 16.3018M9.901 13.4048L9.177 16.3018M18.5 5.5348V8.1348M6.097 6.4558L10.551 8.6608C11.0015 8.88366 11.4974 8.9996 12 8.9996C12.5026 8.9996 12.9985 8.88366 13.449 8.6608L17.903 6.4558C18.082 6.36833 18.2329 6.23234 18.3384 6.06333C18.444 5.89431 18.4999 5.69906 18.4999 5.4998C18.4999 5.30054 18.444 5.10529 18.3384 4.93627C18.2329 4.76725 18.082 4.63127 17.903 4.5438L13.45 2.3388C12.9995 2.11594 12.5036 2 12.001 2C11.4984 2 11.0025 2.11594 10.552 2.3388L6.097 4.5438C5.91797 4.63127 5.76709 4.76725 5.66156 4.93627C5.55603 5.10529 5.50008 5.30054 5.50008 5.4998C5.50008 5.69906 5.55603 5.89431 5.66156 6.06333C5.76709 6.23234 5.91797 6.36833 6.097 6.4558Z"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        id={shapeId}
        fill="none"
      />
    );
  } else if (iconType === 'normal') {
    return (
      <path
        d="M12 17.0798V20.9998M12 17.0798C10.9098 17.0798 9.84407 16.7565 8.937 16.1518C8.7375 16.0186 8.49328 15.97 8.258 16.0168L5.412 16.5868C4.73183 16.7227 4.11979 17.0902 3.68003 17.6266C3.24026 18.163 2.99996 18.8352 3 19.5288V20.9998M12 17.0798C13.0902 17.0798 14.1559 16.7565 15.063 16.1518C15.162 16.0859 15.273 16.0401 15.3897 16.0171C15.5063 15.9941 15.6264 15.9944 15.743 16.0178L18.588 16.5868C19.2682 16.7227 19.8802 17.0902 20.32 17.6266C20.7597 18.163 21 18.8352 21 19.5288V20.9998M16 7.3978V9.9998C16 11.0607 15.5786 12.0781 14.8284 12.8282C14.0783 13.5784 13.0609 13.9998 12 13.9998C10.9391 13.9998 9.92172 13.5784 9.17157 12.8282C8.42143 12.0781 8 11.0607 8 9.9998V7.3978M14.101 13.4048L14.825 16.3018M9.901 13.4048L9.177 16.3018M18.5 5.5348V8.1348M6.097 6.4558L10.551 8.6608C11.0015 8.88366 11.4974 8.9996 12 8.9996C12.5026 8.9996 12.9985 8.88366 13.449 8.6608L17.903 6.4558C18.082 6.36833 18.2329 6.23234 18.3384 6.06333C18.444 5.89431 18.4999 5.69906 18.4999 5.4998C18.4999 5.30054 18.444 5.10529 18.3384 4.93627C18.2329 4.76725 18.082 4.63127 17.903 4.5438L13.45 2.3388C12.9995 2.11594 12.5036 2 12.001 2C11.4984 2 11.0025 2.11594 10.552 2.3388L6.097 4.5438C5.91797 4.63127 5.76709 4.76725 5.66156 4.93627C5.55603 5.10529 5.50008 5.30054 5.50008 5.4998C5.50008 5.69906 5.55603 5.89431 5.66156 6.06333C5.76709 6.23234 5.91797 6.36833 6.097 6.4558Z"
        stroke={solidColor ? 'currentColor' : ''}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        id={shapeId}
        fill="none"
      />
    );
  } else {
    return (
      <svg
        width="80"
        height="80"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 17.0798V20.9998M12 17.0798C10.9098 17.0798 9.84407 16.7565 8.937 16.1518C8.7375 16.0186 8.49328 15.97 8.258 16.0168L5.412 16.5868C4.73183 16.7227 4.11979 17.0902 3.68003 17.6266C3.24026 18.163 2.99996 18.8352 3 19.5288V20.9998M12 17.0798C13.0902 17.0798 14.1559 16.7565 15.063 16.1518C15.162 16.0859 15.273 16.0401 15.3897 16.0171C15.5063 15.9941 15.6264 15.9944 15.743 16.0178L18.588 16.5868C19.2682 16.7227 19.8802 17.0902 20.32 17.6266C20.7597 18.163 21 18.8352 21 19.5288V20.9998M16 7.3978V9.9998C16 11.0607 15.5786 12.0781 14.8284 12.8282C14.0783 13.5784 13.0609 13.9998 12 13.9998C10.9391 13.9998 9.92172 13.5784 9.17157 12.8282C8.42143 12.0781 8 11.0607 8 9.9998V7.3978M14.101 13.4048L14.825 16.3018M9.901 13.4048L9.177 16.3018M18.5 5.5348V8.1348M6.097 6.4558L10.551 8.6608C11.0015 8.88366 11.4974 8.9996 12 8.9996C12.5026 8.9996 12.9985 8.88366 13.449 8.6608L17.903 6.4558C18.082 6.36833 18.2329 6.23234 18.3384 6.06333C18.444 5.89431 18.4999 5.69906 18.4999 5.4998C18.4999 5.30054 18.444 5.10529 18.3384 4.93627C18.2329 4.76725 18.082 4.63127 17.903 4.5438L13.45 2.3388C12.9995 2.11594 12.5036 2 12.001 2C11.4984 2 11.0025 2.11594 10.552 2.3388L6.097 4.5438C5.91797 4.63127 5.76709 4.76725 5.66156 4.93627C5.55603 5.10529 5.50008 5.30054 5.50008 5.4998C5.50008 5.69906 5.55603 5.89431 5.66156 6.06333C5.76709 6.23234 5.91797 6.36833 6.097 6.4558Z"
          stroke={solidColor ? 'currentColor' : ''}
          strokeWidth="0.6"
          strokeLinecap="round"
          strokeLinejoin="round"
          id={shapeId}
          fill="none"
        />
      </svg>
    );
  }
};

export const StudentIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_studentCurrentColorGradient`;
  const newColorId = `${prefix}_studentNewColorGradient`;
  const shapeId = `${prefix}_studentShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.redColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.redColors?.PLUS_3,
      stop: theme.palette.redColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox={viewBoxes[iconType]}>
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
            transform="scale(3.3)"
          />
        </>
      )}
    </SvgIcon>
  );
};
