import type { EmotionCache } from '@emotion/react';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { theme, CornerStickyButton } from '@rumblefish/ui';
import store from '@rumblefish/ui//redux/configureStore';
import type { ComponentsType } from '@rumblefish/ui/context/nextComponentsContext/nextComponents.Context.types';
import { NextComponentsContextProvider } from '@rumblefish/ui/context/nextComponentsContext/nextComponentsContext';
import { ThemeContextProvider } from '@rumblefish/ui/context/themeContext/themeContext';
import Telegram from '@rumblefish/ui/src/assets/svg/RumbleFish23Theme/telegram.svg';
import { themeDark } from '@rumblefish/ui/src/theme/rumblefish23Theme';
import type { AppProps } from 'next/app';
import Script from 'next/dist/client/script';
import Head from 'next/head';
import NextImage from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Provider } from 'react-redux';
import createEmotionCache from '../utils/createEmotionCache';
import { marketingScriptsData } from '../utils/mainSiteMarketingScriptsData';
import { MarketingScripts } from '../utils/marketingScripts';
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  return (
    <>
      <Provider store={store}>
        <MarketingScripts {...marketingScriptsData} />
        <CacheProvider value={emotionCache}>
          <Head>
            <title>Rumblefish - Software Development Services</title>
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width"
            />
            <meta name="color-scheme" content="light dark" />
          </Head>
          <NextComponentsContextProvider
            hooks={{ useRouter: useRouter }}
            components={{
              NextLink: NextLink as ComponentsType['NextLink'],
              Script: Script,
              NextImage: NextImage as unknown as ComponentsType['NextImage'],
            }}>
            <ThemeContextProvider>
              <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Component {...pageProps} />

                <ThemeProvider theme={themeDark}>
                  <CornerStickyButton
                    backgroundColor="#778BEB"
                    backgroundImage={Telegram}
                    text="Start a Telegram chat"
                    animation="paperPlane"
                    link="https://t.me/rumblefishdev"
                  />
                </ThemeProvider>
              </ThemeProvider>
            </ThemeContextProvider>
          </NextComponentsContextProvider>
        </CacheProvider>
      </Provider>
    </>
  );
}
