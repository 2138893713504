import type { StackProps } from '@mui/material';
import { Section } from '@rumblefish/ui/AlgeaTheme';
import React from 'react';
import fintechBooks from '../../assets/webp/fromPNG/fintechGuide/fintechBooks.webp';
import { FintechEbookForm } from '../FintechEbookForm';
import {
  StyledStack,
  StyledTextSpace,
  Chequered,
  StyledHeading,
  StyledDescription,
  StyledDiversionStack,
  LeftEllipse,
  RightEllipse,
  WaveVector,
  StyledKeepScrollingWrapper,
  StyledKeepScrolling,
  StyledBooksImage,
  CenterEllipse,
} from './styles';

export const FintechGuideSection = ({ ...props }: StackProps) => {
  return (
    <Section width="small" backgroundColor="transparent">
      <StyledStack {...props}>
        <Chequered />
        <LeftEllipse />
        <RightEllipse />
        <WaveVector />
        <StyledDiversionStack>
          <StyledTextSpace>
            <StyledHeading>
              The Ultimate
              <br />
              Guide to Building
              <br />
              Your Fintech MVP
            </StyledHeading>
            <StyledDescription>
              Set your fintech product to thrive
              <br />
              in a highly competitive market
            </StyledDescription>
          </StyledTextSpace>

          <FintechEbookForm
            ebookBtnText="Yes, I want a free e-book"
            ebookSx={{
              background:
                'radial-gradient(102.78% 104.72% at -0.77% 0%, rgba(255, 255, 255, 0.075) 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, rgba(255, 255, 255, 0.01)',
            }}
          />
        </StyledDiversionStack>
        <StyledKeepScrollingWrapper>
          <StyledKeepScrolling />
        </StyledKeepScrollingWrapper>
        <CenterEllipse />
        <StyledBooksImage src={fintechBooks}></StyledBooksImage>
      </StyledStack>
    </Section>
  );
};
