import { Theme } from '@mui/material';
import type { MacBookGradient } from './MacBookWindow.types';

import aquaGradient from '../../../assets/webp/fromPNG/Rumblefish23Theme/gradientSmoke/aqua.webp';
import bluePurpleGradient from '../../../assets/webp/fromPNG/Rumblefish23Theme/gradientSmoke/blue-purple.webp';
import greenGradient from '../../../assets/webp/fromPNG/Rumblefish23Theme/gradientSmoke/green.webp';
import lightBlueGradient from '../../../assets/webp/fromPNG/Rumblefish23Theme/gradientSmoke/lightBlue.webp';
import orangePurpleGradient from '../../../assets/webp/fromPNG/Rumblefish23Theme/gradientSmoke/orange-purple.webp';

import orangeGradient from '../../../assets/webp/fromPNG/Rumblefish23Theme/gradientSmoke/orange.webp';
import purpleBlueGradient from '../../../assets/webp/fromPNG/Rumblefish23Theme/gradientSmoke/purple-blue.webp';
import purpleYellowGradient from '../../../assets/webp/fromPNG/Rumblefish23Theme/gradientSmoke/purple-yellow.webp';

export const resolveGradient = (gradient: MacBookGradient) => {
  if (gradient === 'orange-purple') {
    return orangePurpleGradient;
  } else if (gradient === 'orange') {
    return orangeGradient;
  } else if (gradient === 'blue-purple') {
    return bluePurpleGradient;
  } else if (gradient === 'purple-yellow') {
    return purpleYellowGradient;
  } else if (gradient === 'purple-blue') {
    return purpleBlueGradient;
  } else if (gradient === 'aqua') {
    return aquaGradient;
  } else if (gradient === 'green') {
    return greenGradient;
  } else if (gradient === 'lightBlue') {
    return lightBlueGradient;
  }
  return orangeGradient;
};

export const resolveButtonShadow = (
  gradient: MacBookGradient,
  theme: Theme
) => {
  if (gradient === 'orange-purple') {
    return `0px 0px 6px 4px ${theme.palette.redColors?.PLUS_1}, 0px 0px 6px 4px ${theme.palette.redColors?.PLUS_1} inset !important`;
  } else if (gradient === 'orange') {
    return `0px 0px 6px 4px ${theme.palette.orangeColors?.PLUS_1}, 0px 0px 6px 4px ${theme.palette.orangeColors?.PLUS_1} inset !important`;
  } else if (gradient === 'blue-purple') {
    return `0px 0px 6px 4px ${theme.palette.purpleColors?.PLUS_1}, 0px 0px 6px 4px ${theme.palette.purpleColors?.PLUS_1} inset !important`;
  } else if (gradient === 'purple-yellow') {
    return `0px 0px 6px 4px ${theme.palette.raspberryColors?.PLUS_1}, 0px 0px 6px 4px ${theme.palette.raspberryColors?.PLUS_1} inset !important`;
  } else if (gradient === 'purple-blue') {
    return `0px 0px 6px 4px ${theme.palette.magentaColors?.PLUS_2}, 0px 0px 6px 4px ${theme.palette.magentaColors?.PLUS_2} inset !important`;
  } else if (gradient === 'aqua') {
    return `0px 0px 6px 4px ${theme.palette.aquamarineColors?.PLUS_1}, 0px 0px 6px 4px ${theme.palette.aquamarineColors?.PLUS_1} inset !important`;
  } else if (gradient === 'green') {
    return `0px 0px 6px 4px ${theme.palette.greenColors?.PLUS_1}, 0px 0px 6px 4px ${theme.palette.greenColors?.PLUS_1} inset !important`;
  } else if (gradient === 'lightBlue') {
    return `0px 0px 6px 4px ${theme.palette.cyanColors?.PLUS_2}, 0px 0px 6px 4px ${theme.palette.cyanColors?.PLUS_2} inset !important`;
  }
};
