import { Stack, styled, Typography } from '@mui/material';
import chequered from '../../assets/webp/fromPNG/fintechGuide/chequered.webp';

export const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(5),
  margin: theme.spacing(0, 0, 10, 0),
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(6, 0, 6, 0),
    gap: theme.spacing(4),
  },
}));
export const Chequered = styled('div')(({ theme }) => ({
  ['&:before']: {
    content: "''",
    height: `100vh`,
    width: `100%`,
    left: '0%',
    top: `65%`,
    position: 'absolute',
    zIndex: 10,
    backgroundImage: `url(${chequered})`,
    backgroundSize: `100% 65%`,
    backgroundRepeat: `no-repeat`,
    backgroundPositionX: `10%, 100%, 70%`,
    backgroundPositionY: `10%, 2%`,
    [theme.breakpoints.down('xl')]: {
      width: `100%`,
    },
    [theme.breakpoints.down('lg')]: {
      backgroundSize: `100% 55%`,
    },
    [theme.breakpoints.down('md')]: {
      top: `65%`,
      backgroundSize: `auto 70%`,
    },
    [theme.breakpoints.down('sm')]: {
      top: `75%`,
    },
  },
}));
export const StyledIcon = styled(`img`)(() => ({
  transition: 'all 0.4s ease-in-out',
  opacity: 1,
}));
export const StyledHeading = styled(Typography)(({ theme }) => ({
  userSelect: 'none',
  background:
    'linear-gradient(180deg, #FFFFFF 0%, rgba(217, 217, 217, 0.8) 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontFamily: 'Rajdhani',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '115%',
  fontSize: '56px',
  textAlign: 'center',
  letterSpacing: '-0.02em',
  backgroundClip: 'text',
  zIndex: 10,
  margin: theme.spacing(0, 0, 5, 0),

  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '32px',
    lineHeight: '36.8px',
  },
}));
export const StyledBlocksStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',

  ['& > * + *']: {
    borderLeft: '1px dashed rgba(255, 255, 255, 0.1)',
    borderBottom: '1px dashed rgba(255, 255, 255, 0.1)',
  },
  ['& > div:nth-of-type(1)']: {
    borderBottom: '1px dashed rgba(255, 255, 255, 0.1)',
  },
  ['& > div:nth-of-type(4)']: {
    borderLeft: '0px',
  },
  [theme.breakpoints.down('md')]: {
    ['& > div:nth-of-type(3)']: {
      borderLeft: '0px',
    },
    ['& > div:nth-of-type(4)']: {
      borderLeft: '1px dashed rgba(255, 255, 255, 0.1)',
    },
    ['& > div:nth-of-type(5)']: {
      borderLeft: '0px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    ['& > div:nth-of-type(n)']: {
      border: '1px dashed rgba(255, 255, 255, 0.1)',
      borderBottom: '0px',
    },
    ['& > div:nth-of-type(1)']: {
      borderTopLeftRadius: '24px',
      borderTopRightRadius: '24px',
    },
    ['& > div:nth-of-type(6)']: {
      borderBottom: '1px dashed rgba(255, 255, 255, 0.1)',
      borderBottomLeftRadius: '24px',
      borderBottomRightRadius: '24px',
    },
  },
}));

export const Block = styled(Stack)(({ theme }) => ({
  flex: '1 0 30%',
  display: 'flex',
  gap: '2rem',
  backdropFilter: 'blur(12px)',
  // background: "rgba(255, 255, 255, 0.03)",

  padding: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    // width: "350px",
    padding: theme.spacing(2),
    gap: '1rem',
    flex: '1 0 48%',
  },

  [theme.breakpoints.down('sm')]: {
    backdropFilter: 'blur(0px)',
    padding: theme.spacing(2),
    gap: '1rem',
    flex: '1 0 90%',
  },
}));
export const BlockIconStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {},
}));

export const IconCircleStack = styled(Stack)(({ theme }) => ({
  borderImageSource:
    'radial-gradient(58.93% 157.84% at 98.5% 100%, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,radial-gradient(52.75% 141.29% at 0% 0%, rgba(255, 255, 255, 0.15) 0%, rgba(249, 39, 127, 0) 100%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */',
  background:
    'radial-gradient(47.45% 48.31% at -0.77% 0%, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 40%),radial-gradient(52.75% 141.29% at 0% 0%, rgba(255, 255, 255, 0.09) 0%, rgba(249, 39, 127, 0) 60%, rgba(255, 255, 255, 0) 100%),radial-gradient(58.93% 157.84% at 98.5% 10%, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%),linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03))',
  borderRadius: '100%',
  padding: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
export const StyledNumberText = styled(Typography)(() => ({
  userSelect: 'none',
  background:
    'linear-gradient(152.46deg, #FFFFFF -22.85%, rgba(255, 255, 255, 0) 100%), #F47AFF',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  fontFamily: 'Rajdhani',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '48px',
  lineHeight: '115%',
  letterSpacing: '-0.02em',

  fontVariationSettings: 'slnt 0',
}));
export const StyledBlockTitle = styled(Typography)(({ theme }) => ({
  userSelect: 'none',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '650',
  fontSize: '24px',
  lineHeight: '150%',
  textAlign: 'center',
  letterSpacing: '-0.01em',
  color: theme.palette.colorWhite,
  fontVariationSettings: 'slnt 0',
}));
export const StyledBlockDescription = styled(Typography)(({ theme }) => ({
  userSelect: 'none',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '350',
  fontSize: '14px',
  lineHeight: '150%',
  textAlign: 'center',
  letterSpacing: '-0.01em',
  color: theme.palette.text.secondary,
  fontVariationSettings: 'slnt 0',
}));
export const StartupsStyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex',
  width: '100%',
  gap: theme.spacing(5),
  marginTop: theme.spacing(10),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(6),
  },
}));
export const StartupHeading = styled(Typography)(({ theme }) => ({
  userSelect: 'none',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '350',
  fontSize: '24px',
  lineHeight: '150%',
  letterSpacing: '-0.01em',
  color: 'rgba(255,255,255,0.75)',
  fontVariationSettings: 'slnt 0',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
}));

export const CurrentStartupStyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(4),
    justifyContent: 'center',
  },
}));

export const FreeEbookStack = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(10),
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(4),
}));
export const FreeEbookHeading = styled(Typography)(({ theme }) => ({
  userSelect: 'none',
  background:
    'linear-gradient(180deg, #FFFFFF 0%, rgba(217, 217, 217, 0.8) 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  fontFamily: 'Rajdhani',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '56px',
  lineHeight: '115%',
  letterSpacing: '-0.02em',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    fontSize: '50px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '45px',
  },
}));
export const Line = styled('div')(({ theme }) => ({
  height: '1px',
  width: '100%',
  backgroundColor: '#FFFFFF',
  opacity: 0.15,
  marginTop: theme.spacing(10),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(6),
  },
}));
