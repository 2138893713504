import { createSlice, Slice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { TTelegramBtn } from './types';

const initialState: TTelegramBtn = {
  visible: true,
};

export const telegramBtnSlice: Slice = createSlice({
  name: 'telegramBtn',
  initialState,
  reducers: {
    setBtnVisibility: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
  },
});

export const { setBtnVisibility } = telegramBtnSlice.actions;

export const isBtnVisible = (state: TTelegramBtn) => state.visible;

export default telegramBtnSlice.reducer;
