import React from 'react';

import blockchainBadge from '../../../assets/webp/fromPNG/clutchBadges/top_clutch.co_blockchain_company_poland_2024.webp';
import cloudConsultingBadge from '../../../assets/webp/fromPNG/clutchBadges/top_clutch.co_cloud_consulting_company_krakow_2024.webp';
import smartContractDevelopmentBadge from '../../../assets/webp/fromPNG/clutchBadges/top_clutch.co_smart_contract_development_company_krakow_2024.webp';
import { AnimatedLogo } from '../AnimatedLogo';
import { Link } from '../Link';
import type { ClickableSubtitleProps } from './Footer.types';
import {
  Title,
  Subtitle,
  AboutUsText,
  ColumnStack,
  StyledImage,
  AboutUsStack,
  FooterSection,
  StyledWrapper,
  SubmenusStack,
  ClutchBadgesStack,
  AboutUsInfoWrapper,
  SingleSubmenuStack,
} from './styles';

const ClickableSubtitle = ({ children, link }: ClickableSubtitleProps) => {
  return (
    <Link to={link}>
      <Subtitle variant="strong_100">{children}</Subtitle>
    </Link>
  );
};

export const Footer = () => {
  return (
    <FooterSection width="normal" borderTopOn>
      <StyledWrapper>
        <AboutUsStack>
          <AnimatedLogo variant="default" />
          <AboutUsInfoWrapper>
            <AboutUsText variant="strong_100">
              RUMBLEFISH POLAND SP Z O.O.
            </AboutUsText>
            <AboutUsText variant="regular_100">
              Filipa Eisenberga 11/3 31-523 Kraków, Polska
            </AboutUsText>
          </AboutUsInfoWrapper>
          <AboutUsInfoWrapper>
            <AboutUsText variant="regular_100">NIP: 6772425725</AboutUsText>
            <AboutUsText variant="regular_100">REGON: 368368380</AboutUsText>
            <AboutUsText variant="regular_100">KRS: 0000696628</AboutUsText>
          </AboutUsInfoWrapper>
          <AboutUsInfoWrapper>
            <AboutUsText variant="regular_100">P: +48 601 265 364</AboutUsText>
            <AboutUsText variant="regular_100">
              E: hello@rumblefish.dev
            </AboutUsText>
          </AboutUsInfoWrapper>
          <AboutUsText marginTop={'auto'} variant="regular_100">
            Copyright © {new Date().getFullYear()} Rumblefish
          </AboutUsText>
        </AboutUsStack>
        <SubmenusStack>
          <ColumnStack>
            <SingleSubmenuStack>
              <Title variant="strong_300">Services</Title>
              <ClickableSubtitle link="/services/aws-cloud-solutions/">
                AWS Cloud Solutions
              </ClickableSubtitle>
              <ClickableSubtitle link="/services/web-development/">
                Web development
              </ClickableSubtitle>
              <ClickableSubtitle link="/services/mobile-development/">
                Mobile development
              </ClickableSubtitle>
              <ClickableSubtitle link="/services/ai-chat-assistant/">
                AI Chat Assistant Development
              </ClickableSubtitle>
              <ClickableSubtitle link="/services/defi-development/">
                DeFi Development
              </ClickableSubtitle>
              <ClickableSubtitle link="/services/fintech-software-development/">
                Fintech Software Development
              </ClickableSubtitle>
              <ClickableSubtitle link="/services/blockchain-software-development/">
                Blockchain Software Development
              </ClickableSubtitle>
              <ClickableSubtitle link="/services/dedicated-development-teams/">
                Dedicated Development Teams
              </ClickableSubtitle>
              <ClickableSubtitle link="/services/product-design">
                UI/UX
              </ClickableSubtitle>
              <ClickableSubtitle link="/startup">
                IT Services for startup
              </ClickableSubtitle>
              <ClickableSubtitle link="/services">
                View all services
              </ClickableSubtitle>
            </SingleSubmenuStack>
          </ColumnStack>
          <ColumnStack>
            <SingleSubmenuStack>
              <Title variant="strong_300">Products</Title>
              <ClickableSubtitle link="/evm-debugger">
                EVM Debugger
              </ClickableSubtitle>
              <ClickableSubtitle link="/deva-wallet">
                Deva Wallet
              </ClickableSubtitle>
            </SingleSubmenuStack>

            <SingleSubmenuStack>
              <Title variant="strong_300">Careers</Title>
              <ClickableSubtitle link="/careers">
                Join Our Team
              </ClickableSubtitle>
              {/* <ClickableSubtitle link="/blockchainhub">
                Blockchain Training Hub
              </ClickableSubtitle> */}
            </SingleSubmenuStack>
          </ColumnStack>
          <ColumnStack>
            <SingleSubmenuStack>
              <Title variant="strong_300">Other</Title>
              <ClickableSubtitle link="/case-studies/">
                Case studies
              </ClickableSubtitle>
              <ClickableSubtitle link="/team/">About us</ClickableSubtitle>
              <ClickableSubtitle link="/contact/">Contact</ClickableSubtitle>
              <ClickableSubtitle link="/blog">Visit our Blog</ClickableSubtitle>
            </SingleSubmenuStack>
          </ColumnStack>
          <ColumnStack>
            <SingleSubmenuStack>
              <Title variant="strong_300">Social</Title>
              <ClickableSubtitle link="https://www.linkedin.com/company/rumblefishdev/">
                Linkedin
              </ClickableSubtitle>
              <ClickableSubtitle link="https://www.facebook.com/rumblefishsoftwaredevelopment">
                Facebook
              </ClickableSubtitle>
              <ClickableSubtitle link="https://twitter.com/rumblefishdev">
                X
              </ClickableSubtitle>
            </SingleSubmenuStack>
          </ColumnStack>
          <ColumnStack>
            <Link to="https://bit.ly/rf-clutch" target="_blank">
              <ClutchBadgesStack>
                <StyledImage
                  src={blockchainBadge}
                  alt="clutch_top_blockchain_company_poland_2024"
                />
                <StyledImage
                  src={cloudConsultingBadge}
                  alt="clutch_top_cloud_consulting_company_krakow_2024"
                />
                <StyledImage
                  src={smartContractDevelopmentBadge}
                  alt="clutch_top_smart_contract_development_company_krakow_2024"
                />
              </ClutchBadgesStack>
            </Link>
          </ColumnStack>
        </SubmenusStack>
      </StyledWrapper>
    </FooterSection>
  );
};
