import type { TypographyOptions } from '@mui/material/styles/createTypography';
import { createBreakpoints } from '@mui/system';
import type { CSSProperties } from 'react';
import { useTheme } from '@mui/material';
import { colorsDark } from './colors';
import { breakpoints as breakpointsValues } from './breakpoints';
import { fluidSize } from './utils';

const desktopBreakpoint = 'xl';
const mobileBreakpoint = 'sm';

const breakpoints = createBreakpoints(breakpointsValues);
export interface ExtendedTypographyVariantDesign23 {
  regular_700: true;
  regular_600: true;
  regular_500: true;
  regular_400: true;
  regular_300: true;
  regular_200: true;
  regular_100: true;
  strong_700: true;
  strong_600: true;
  strong_500: true;
  strong_400: true;
  strong_300: true;
  strong_200: true;
  strong_100: true;
  display_lg: true;
  display_md: true;
  display_sm: true;
  display_xs: true;
  display_xxs: true;
  display_xxxs: true;
  paragraphMdEmphasis: true;
  paragraphSmEmphasis: true;
}
export interface ExtendedTypographyOptions extends TypographyOptions {
  regular_700: CSSProperties;
  regular_600: CSSProperties;
  regular_500: CSSProperties;
  regular_400: CSSProperties;
  regular_300: CSSProperties;
  regular_200: CSSProperties;
  regular_100: CSSProperties;
  strong_700: CSSProperties;
  strong_600: CSSProperties;
  strong_500: CSSProperties;
  strong_400: CSSProperties;
  strong_300: CSSProperties;
  strong_200: CSSProperties;
  strong_100: CSSProperties;
  display_lg: CSSProperties;
  display_md: CSSProperties;
  display_sm: CSSProperties;
  display_xs: CSSProperties;
  display_xxs: CSSProperties;
  display_xxxs: CSSProperties;
  paragraphMdEmphasis: CSSProperties;
  paragraphSmEmphasis: CSSProperties;
}
const greyPrimaryColor = colorsDark.greyColors.MINUS_3;

const clashDisplayStyle = {
  fontFamily: 'ClashDisplay',
  fontWeight: 650,
  color: greyPrimaryColor,
  letterSpacing: '0.2px',

  WebkitTouchCallout: 'none',
};

const satoshiRegularStyle = {
  fontFamily: 'Satoshi',
  fontWeight: 400,
  color: greyPrimaryColor,
  letterSpacing: '0.2px',

  WebkitTouchCallout: 'none',
};
const satoshiStrongStyle = {
  fontFamily: 'Satoshi',
  fontWeight: 600,
  color: greyPrimaryColor,

  WebkitTouchCallout: 'none',
};

export const typography = {
  fontFamily: ['Satoshi,ClashDisplay'].join(','),
  regular_700: {
    ...satoshiRegularStyle,
    lineHeight: fluidSize({ minSize: 44, maxSize: 60 }),
    fontSize: fluidSize({ minSize: 32, maxSize: 44 }),
    [breakpoints.up(desktopBreakpoint)]: {
      fontSize: '44px',
      lineHeight: '60px',
    },
    [breakpoints.down(mobileBreakpoint)]: {
      fontSize: '32px',
      lineHeight: '44px',
    },
  },
  regular_600: {
    ...satoshiRegularStyle,
    lineHeight: fluidSize({ minSize: 36, maxSize: 44 }),
    fontSize: fluidSize({ minSize: 24, maxSize: 32 }),
    [breakpoints.up(desktopBreakpoint)]: {
      fontSize: '32px',
      lineHeight: '44px',
    },
    [breakpoints.down(mobileBreakpoint)]: {
      fontSize: '24px',
      lineHeight: '36px',
    },
  },
  regular_500: {
    ...satoshiRegularStyle,
    lineHeight: fluidSize({ minSize: 32, maxSize: 36 }),
    fontSize: fluidSize({ minSize: 18, maxSize: 24 }),
    [breakpoints.up(desktopBreakpoint)]: {
      fontSize: '24px',
      lineHeight: '36px',
    },
    [breakpoints.down(mobileBreakpoint)]: {
      fontSize: '18px',
      lineHeight: '32px',
    },
  },
  regular_400: {
    ...satoshiRegularStyle,
    lineHeight: '32px',
    fontSize: '18px',
  },
  regular_300: {
    ...satoshiRegularStyle,
    lineHeight: '28px',
    fontSize: '16px',
  },
  regular_200: {
    ...satoshiRegularStyle,
    lineHeight: '24px',
    fontSize: '14px',
  },
  regular_100: {
    ...satoshiRegularStyle,
    lineHeight: '20px',
    fontSize: '12px',
  },
  strong_700: {
    ...satoshiStrongStyle,
    lineHeight: fluidSize({ minSize: 44, maxSize: 64 }),
    fontSize: fluidSize({ minSize: 32, maxSize: 48 }),
    letterSpacing: fluidSize({ minSize: -0.5, maxSize: -0.7 }),
    [breakpoints.up(desktopBreakpoint)]: {
      fontSize: '48px',
      lineHeight: '64px',
      letterSpacing: '-0.7px',
    },
    [breakpoints.down(mobileBreakpoint)]: {
      fontSize: '32px',
      lineHeight: '44px',
      letterSpacing: '-0.5px',
    },
  },
  strong_600: {
    ...satoshiStrongStyle,
    lineHeight: fluidSize({ minSize: 36, maxSize: 44 }),
    fontSize: fluidSize({ minSize: 24, maxSize: 32 }),
    letterSpacing: fluidSize({ minSize: -0.3, maxSize: -0.5 }),
    [breakpoints.up(desktopBreakpoint)]: {
      fontSize: '32px',
      lineHeight: '44px',
      letterSpacing: '-0.5px',
    },
    [breakpoints.down(mobileBreakpoint)]: {
      fontSize: '24px',
      lineHeight: '36px',
      letterSpacing: '-0.3px',
    },
  },
  strong_500: {
    ...satoshiStrongStyle,
    lineHeight: fluidSize({ minSize: 32, maxSize: 36 }),
    fontSize: fluidSize({ minSize: 18, maxSize: 24 }),
    letterSpacing: fluidSize({ minSize: 0.2, maxSize: -0.3 }),
    [breakpoints.up(desktopBreakpoint)]: {
      fontSize: '24px',
      lineHeight: '36px',
      letterSpacing: '-0.3px',
    },
    [breakpoints.down(mobileBreakpoint)]: {
      fontSize: '18px',
      lineHeight: '32px',
      letterSpacing: '0.2px',
    },
  },
  strong_400: {
    ...satoshiStrongStyle,
    lineHeight: '32px',
    fontSize: '18px',
    letterSpacing: '0.2px',
  },
  strong_300: {
    ...satoshiStrongStyle,
    lineHeight: '28px',
    fontSize: '16px',
    letterSpacing: '-0.1px',
  },
  strong_200: {
    ...satoshiStrongStyle,
    lineHeight: '24px',
    fontSize: '14px',
    letterSpacing: '-0.1px',
  },
  strong_100: {
    ...satoshiStrongStyle,
    lineHeight: '20px',
    fontSize: '12px',
  },
  display_lg: {
    ...clashDisplayStyle,
    lineHeight: fluidSize({ minSize: 56, maxSize: 112 }),
    fontSize: fluidSize({ minSize: 64, maxSize: 128 }),
    [breakpoints.up(desktopBreakpoint)]: {
      fontSize: '128px',
      lineHeight: '112px',
    },
    [breakpoints.down(mobileBreakpoint)]: {
      fontSize: '64px',
      lineHeight: '56px',
    },
  },
  display_md: {
    ...clashDisplayStyle,
    lineHeight: fluidSize({ minSize: 48, maxSize: 88 }),
    fontSize: fluidSize({ minSize: 48, maxSize: 96 }),
    [breakpoints.up(desktopBreakpoint)]: {
      fontSize: '96px',
      lineHeight: '88px',
    },
    [breakpoints.down(mobileBreakpoint)]: {
      fontSize: '48px',
      lineHeight: '48px',
    },
  },
  display_sm: {
    ...clashDisplayStyle,
    lineHeight: fluidSize({ minSize: 32, maxSize: 64 }),
    fontSize: fluidSize({ minSize: 32, maxSize: 64 }),
    [breakpoints.up(desktopBreakpoint)]: {
      fontSize: '64px',
      lineHeight: '64px',
    },
    [breakpoints.down(mobileBreakpoint)]: {
      fontSize: '32px',
      lineHeight: '32px',
    },
  },
  display_xs: {
    ...clashDisplayStyle,
    lineHeight: fluidSize({ minSize: 36, maxSize: 56 }),
    fontSize: fluidSize({ minSize: 36, maxSize: 56 }),
    [breakpoints.up(desktopBreakpoint)]: {
      fontSize: '56px',
      lineHeight: '56px',
    },
    [breakpoints.down(mobileBreakpoint)]: {
      fontSize: '36px',
      lineHeight: '36px',
    },
  },
  display_xxs: {
    ...clashDisplayStyle,
    lineHeight: fluidSize({ minSize: 24, maxSize: 28 }),
    fontSize: fluidSize({ minSize: 24, maxSize: 28 }),
    [breakpoints.up(desktopBreakpoint)]: {
      fontSize: '28px',
      lineHeight: '28px',
    },
    [breakpoints.down(mobileBreakpoint)]: {
      fontSize: '24px',
      lineHeight: '24px',
    },
  },
  display_xxxs: {
    ...clashDisplayStyle,
    lineHeight: fluidSize({ minSize: 16, maxSize: 20 }),
    fontSize: fluidSize({ minSize: 16, maxSize: 20 }),
    [breakpoints.up(desktopBreakpoint)]: {
      fontSize: '20px',
      lineHeight: '20px',
    },
    [breakpoints.down(mobileBreakpoint)]: {
      fontSize: '16px',
      lineHeight: '16px',
    },
  },
  paragraphMdEmphasis: {
    fontFamily: 'RussoOne',
    fontWeight: 400,
    color: '#FFFFFF',
    lineHeight: '40px',
    fontSize: '21px',
  },
  paragraphSmEmphasis: {
    fontFamily: 'BaiJamjuree',
    fontWeight: 600,
    color: '#898DA9',
    lineHeight: '17.7px',
    fontSize: '12.4px',
  },
} as ExtendedTypographyOptions;
