import { useContext } from 'react';
import { NextComponentsContext } from '../../../context/nextComponentsContext/nextComponentsContext';

export const useRouterEventually = () => {
  const hooks = useContext(NextComponentsContext)?.hooks;
  if (hooks && 'useRouter' in hooks) {
    return hooks.useRouter();
  }

  return undefined;
};
