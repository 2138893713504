import React from 'react';
import type { CornerStickyButtonProps } from './CornerStickyButton.types';
import { StyledButton, StyledCircle, StyledMovableText } from './styles';
import { useRouterEventually } from '../../hooks';
import { isBtnVisible } from '@rumblefish/ui/redux/reducers/telegramBtn';
import { type RootState } from '@rumblefish/ui//redux/configureStore';
import { useSelector } from 'react-redux';
export const CornerStickyButton = (props: CornerStickyButtonProps) => {
  const router = useRouterEventually();
  const isTelegramButtonVisible = useSelector((state: RootState) =>
    isBtnVisible(state.telegramBtn)
  );
  const pagesWithoutTelegram = ['/startup'];
  const currentPathname = router?.pathname ?? '';
  const shouldDisplay = !pagesWithoutTelegram.includes(currentPathname);

  return (
    <>
      <a target="_blank" rel="noreferrer" href={props.link}>
        <StyledButton
          animation={props.animation ?? ''}
          backgroundColor={props.backgroundColor ?? 'black'}
          isVisible={isTelegramButtonVisible && shouldDisplay}>
          <StyledMovableText
            textColor={props.textColor ?? 'white'}
            variant="regular_100">
            {props.text}
          </StyledMovableText>
          <StyledCircle>
            <img alt="icon" src={props.backgroundImage} />
          </StyledCircle>
        </StyledButton>
      </a>
    </>
  );
};
