/* eslint-disable @typescript-eslint/naming-convention */
import type { ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import noiseBg from '../../assets/webp/fromPNG/noise.webp';
import { breakpoints } from './breakpoints';
import { mixinsDark, mixinsLight } from './mixins';
import { overrides as components } from './overrides';
import { paletteDark, paletteLight } from './palette';
import type { ExtendedTypographyVariantDesign23 as IextendedTypo } from './typography';
import { typography } from './typography';

import { fluidFont } from '../algaeTheme/utils';
import { colorsDark } from './colors'; // Adjust the import path as necessary
import {
  fluidSize,
  getViewportHeight,
  IFluidSize,
  isMobile,
  isPhone,
  isWebkit,
} from './utils';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends IextendedTypo {}
}
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}
declare module '@mui/material/styles' {
  interface Theme {
    utils: {
      getViewportHeight: () => number;
      isMobile: () => boolean;
      isPhone: () => boolean;
      isWebkit: () => boolean;
      fluidFont: (min: number, max: number) => string;
      fluidSize: ({
        minSize,
        maxSize,
        increase,
        maxBp,
        minBp,
      }: IFluidSize) => string;
    };
  }
  interface ThemeOptions {
    utils?: {
      getViewportHeight: () => number;
      isMobile: () => boolean;
      isPhone: () => boolean;
      isWebkit: () => boolean;
      fluidFont: (min: number, max: number) => string;
      fluidSize: ({
        minSize,
        maxSize,
        increase,
        maxBp,
        minBp,
      }: IFluidSize) => string;
    };
  }
}

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    counterNumbersGradient: CSSProperties;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    flat: true;
    link: true;
    primary: true;
    icon: true;
  }
}
const spacing = 8;
const themeTemplate: ThemeOptions = {
  spacing,
  typography: { ...typography },

  breakpoints,
  utils: {
    isWebkit: isWebkit,
    isMobile: isMobile,
    isPhone: isPhone,
    fluidFont: fluidFont,
    fluidSize: fluidSize,
    getViewportHeight: getViewportHeight,
  },
};

export const themeDark = createTheme({
  ...themeTemplate,
  palette: paletteDark,
  components: {
    MuiPopover: {
      styleOverrides: {
        root: {
          [`&#menu- .MuiPaper-root.MuiPopover-paper.MuiMenu-paper`]: {
            backgroundColor: 'transparent',
            overflow: 'hidden',
            maxHeight: '200px',
            marginTop: '8px',
            borderRadius: '10px',
            border: '2px solid',
            borderColor: colorsDark.raspberryColors?.PLUS_1,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides:
        components?.MuiCssBaseline?.styleOverrides +
        `
          body {
            background: url(${noiseBg}), ${paletteDark.background?.default};
          }
        `,
    },
  },
  mixins: {
    ...mixinsDark,
  },
} as ThemeOptions);

export const themeLight = createTheme({
  ...themeTemplate,
  palette: paletteLight,
  components: {
    MuiCssBaseline: {
      styleOverrides:
        components?.MuiCssBaseline?.styleOverrides +
        `
          body {
            background:  ${paletteLight.background?.default};
          }
        `,
    },
  },
  mixins: {
    ...mixinsLight,
  },
} as ThemeOptions);
