import { Stack, styled, Typography } from '@mui/material';
import ReCaptcha from 'react-google-recaptcha';

export const StyledStack = styled(Stack)(({ theme }) => ({
  flex: 0.7,
  [theme.breakpoints.down('xl')]: {
    flex: 1,
  },
}));
export const StyledRecaptcha = styled(ReCaptcha)(({ theme }) => ({
  iframe: {
    borderRadius: '7px',
    padding: theme.spacing(0.2),
  },
  marginTop: theme.spacing(4),
}));
export const StyledLoadingIcon = styled(`img`)(() => ({
  animation: 'spin 4s linear infinite',
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(360deg)',
    },
    '100%': {
      transform: 'rotate(deg0)',
    },
  },
}));

export const StyledButtonWrapper = styled(Stack)(({ theme }) => ({
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: theme.spacing(4),
}));
export const CheckboxLabelText = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
  height: '100%',

  ['a']: {
    textDecoration: 'underline',
  },
}));
