import { Stack, styled } from '@mui/material';
import { motion } from 'framer-motion';
import bubbleNoiseDark from '../../../assets/webp/fromPNG/Rumblefish23Theme/bubbleNoise.webp';
export const StyledStack = styled(Stack)(() => ({
  width: '100%',
  height: '100%',
  background: 'red',
}));
export const NoiseBackground = styled(Stack)(() => ({
  // backgroundImage: `url(${bubbleNoiseDark})`,
  // width: '100%',
  // height: '100%',
  position: 'absolute',
  // top: 0,
  // left: 0,
  // borderRadius: '50%',
  // zIndex: 2,
}));

export const StyledMotionDiv = styled(motion.div)<{
  boxshadow: string | undefined;
}>(({ theme, boxshadow }) => ({
  ...theme.mixins.neutralGradientTransparent,

  '::after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    aspectRatio: '1/1',
    borderRadius: '50%',
    transition: 'all .4s',
    opacity: 0,
    boxShadow: boxshadow,
  },
  '&:hover': {
    '::after': {
      opacity: 1,
    },
  },

  position: 'relative',
  overflow: 'hidden',
  padding: theme.spacing(1.8),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  aspectRatio: '1/1',
  borderRadius: '50%',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    padding: theme.spacing(1.2),
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: theme.spacing(1.8),
  },
}));
export const StyledCircleContainer = styled(motion.div)(({ theme }) => ({
  zIndex: 100,
  cursor: 'default',
  position: 'absolute',
  aspectRatio: '1/1',
  borderRadius: '50%',
  img: {
    pointerEvents: 'none',
  },
  [theme.breakpoints.down('md')]: {
    animation: '0 !important',
  },
  willChange:
    'scale, translateX, translateY, translateZ, transform, transition, animation !important',
}));
