import React from 'react';
import { NavLink } from '@rumblefish/ui';
import { Button } from '@rumblefish/ui/Rumblefish23Theme';
import { NavigationButtonProps } from './NavigationButton.types';
import { ScreenReaderText } from '../../ScreenReaderText';

export const NavigationButton: React.FC<NavigationButtonProps> = ({
  target,
  buttonText,
  className,
  ariaLabel,
  color,
}) => {
  return (
    <NavLink exact to={target}>
      <Button className={className} secondaryVariant={true} shadowColor={color}>
        {buttonText}
        <ScreenReaderText> {ariaLabel} </ScreenReaderText>
      </Button>
    </NavLink>
  );
};
