import { type Theme, colors, styled, Typography } from '@mui/material';
import React from 'react';
import { colorsDark } from '../../../theme/rumblefish23Theme/colors';
import type { RichTextProps, IReplace } from '../../RichText/RichText.types';
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { fluidSize } from '../../../theme/rumblefish23Theme/utils';
const PREFIX = 'RichText';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')<{
  contentPrimary?: boolean;
  reverseMargin?: boolean;
}>(({ contentPrimary = false, reverseMargin = false, theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    color: colorsDark.invertedContent.PRIMARY,
    marginBottom: reverseMargin ? theme.spacing(0) : theme.spacing(6),
    '& h1, h2, h3, h4, .MuiTypography-display_lg, .MuiTypography-display_md, .MuiTypography-display_sm, .MuiTypography-display_xs, .MuiTypography-display_xxs, .MuiTypography-display_xxxs':
      {
        ...theme.mixins.neutralGradient,
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },
    '& .MuiTypography-regular_100, .MuiTypography-regular_200, .MuiTypography-regular_300, .MuiTypography-regular_400, .MuiTypography-regular_500, .MuiTypography-regular_600, .MuiTypography-regular_700,.MuiTypography-strong_100, .MuiTypography-strong_200, .MuiTypography-strong_300, .MuiTypography-strong_400, .MuiTypography-strong_500, .MuiTypography-strong_600, .MuiTypography-strong_700 ':
      {
        color: contentPrimary
          ? colorsDark.invertedContent.PRIMARY
          : colorsDark.invertedContent.SECONDARY,
        margin: reverseMargin
          ? theme.spacing(0, 0, 3, 0)
          : theme.spacing(3, 0, 0, 0),
      },
    '& h1, h2': {
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    '& h1, h2, .MuiTypography-display_lg, .MuiTypography-display_md, .MuiTypography-display_sm':
      {
        margin: reverseMargin
          ? theme.spacing(0, 0, 8, 0)
          : theme.spacing(8, 0, 0, 0),
      },
    '& h3, h4, .MuiTypography-display_xs,': {},
    '&  .MuiTypography-display_xxs, .MuiTypography-display_xxxs': {
      margin: reverseMargin
        ? theme.spacing(0, 0, 6, 0)
        : theme.spacing(6, 0, 0, 0),
    },
    '& p b': {
      display: 'inline-block',
      fontWeight: 600,
      color: colorsDark.invertedContent.PRIMARY,
    },
    '& ol': {
      fontSize: '1.125rem',
      color: contentPrimary
        ? colorsDark.invertedContent.PRIMARY
        : colorsDark.invertedContent.SECONDARY,
      fontWeight: 600,
    },
    '& ul': {
      textAlign: 'left',
      margin: theme.spacing(0, 0, 2, 0),
      '& li': {
        position: 'relative',
        color: contentPrimary
          ? colorsDark.invertedContent.PRIMARY
          : colorsDark.invertedContent.SECONDARY,
        fontFamily: 'Satoshi',
        fontWeight: 400,
        letterSpacing: '0.2px',

        WebkitTouchCallout: 'none',
        lineHeight: fluidSize({ minSize: 28, maxSize: 32 }),
        fontSize: fluidSize({ minSize: 16, maxSize: 22 }),
        [theme.breakpoints.up('md')]: {
          fontSize: '22px',
          lineHeight: '32px',
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '16px',
          lineHeight: '32px',
        },
        '& fragment>ul>li': {
          '&:before': {
            content: '"unset !important"',
          },
        },
        '& p': {
          display: 'inline',
        },
      },
    },
  },
}));
const options = {
  replace: ({ name, children }: IReplace) => {
    if (name === 'h1') {
      return (
        <Typography variant="display_lg">
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === 'h2') {
      return (
        <Typography variant="display_md">
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === 'h3') {
      return (
        <Typography variant="display_sm">
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === 'h4') {
      return (
        <Typography variant="display_xs">
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === 'h5') {
      return (
        <Typography variant="display_xxs">
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === 'h6') {
      return (
        <Typography variant="display_xxxs">
          {domToReact(children, options)}
        </Typography>
      );
    }

    if (name === 'p') {
      return (
        <Typography component="p" variant="regular_500">
          {domToReact(children, options)}
        </Typography>
      );
    }
  },
} as HTMLReactParserOptions;

export const RichText: React.FC<RichTextProps> = ({
  content,
  contentPrimary,
  reverseMargin,
}) => {
  return (
    <Root
      className={classes.root}
      contentPrimary={contentPrimary}
      reverseMargin={reverseMargin}>
      {parse(content, options)}
    </Root>
  );
};
