import { useTheme } from '@mui/material';
import { Link, Button } from '@rumblefish/ui/Rumblefish23Theme';
import { useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import type { SideBannerProps } from './SideBanner.types';
import {
  StyledButton,
  StyledMotionDiv,
  StyledBannerText,
  StyledBannerImage,
  StyledMainContainer,
  StyledContentSection,
  StyledAbsoluteContainer,
} from './styles';

export const SideBanner = (props: SideBannerProps) => {
  const {
    verticalBannerImage,
    horizontalBannerImage,
    bannerText,
    buttonText,
    redirectUrl,
  } = props;
  const isMobile = useTheme().utils.isMobile();
  const control = useAnimation();
  const mobileAnimationVariant = {
    visible: { y: 0 },
    hidden: { y: '100%' },
  };
  const desktopAnimationVariant = {
    visible: { x: 0 },
    hidden: { x: '100%' },
  };

  useEffect(() => {
    control.start(props.show ? 'visible' : 'hidden');
  }, [control, props.show]);

  return (
    <>
      <StyledAbsoluteContainer>
        <StyledMotionDiv
          transition={{ duration: 0.4 }}
          variants={isMobile ? mobileAnimationVariant : desktopAnimationVariant}
          initial="hidden"
          animate={control}>
          <StyledMainContainer>
            <StyledBannerImage
              verticalBannerImage={verticalBannerImage}
              horizontalBannerImage={horizontalBannerImage}
            />
            <StyledContentSection>
              <StyledBannerText>{bannerText}</StyledBannerText>
              <Link to={redirectUrl || ''}>
                <Button
                  sx={{
                    display: {
                      md: 'none',
                    },
                  }}
                  variant="link">
                  {buttonText}
                </Button>

                <StyledButton
                  sx={{
                    width: '100%',
                    whiteSpace: 'nowrap',
                    display: {
                      xs: 'none',
                      md: 'flex',
                    },
                  }}>
                  {buttonText}
                </StyledButton>
              </Link>
            </StyledContentSection>
          </StyledMainContainer>
        </StyledMotionDiv>
      </StyledAbsoluteContainer>
    </>
  );
};
