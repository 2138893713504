import React from 'react';
import {
  StyledInfoContainer,
  StyledInfoTitle,
  StyledInfoDescription,
  StyledTechStackContainer,
  StyledTechStackItem,
} from './styles';
import { CaseStudyDetailsStackProps } from './CaseStudyDetailsStack.types';
import { isNotEmptyString } from '../utils';

export const CaseStudyDetailsStack: React.FC<CaseStudyDetailsStackProps> = ({
  collaborationTimeframe,
  technologies,
  services,
  teamSize,
}) => {
  return (
    <>
      {isNotEmptyString(collaborationTimeframe) && (
        <StyledInfoContainer>
          <StyledInfoTitle variant="strong_200">
            Collaboration timeframe:
          </StyledInfoTitle>
          <StyledInfoDescription variant="regular_300">
            {collaborationTimeframe}
          </StyledInfoDescription>
        </StyledInfoContainer>
      )}
      {isNotEmptyString(technologies) && (
        <StyledInfoContainer>
          <StyledInfoTitle variant="strong_200">Tech stack:</StyledInfoTitle>
          <StyledTechStackContainer>
            {technologies?.split(',').map((item, index) => (
              <StyledTechStackItem key={index} variant="regular_200">
                {item}
              </StyledTechStackItem>
            ))}
          </StyledTechStackContainer>
        </StyledInfoContainer>
      )}
      {isNotEmptyString(services) && (
        <StyledInfoContainer>
          <StyledInfoTitle variant="strong_200">Services:</StyledInfoTitle>
          <StyledInfoDescription variant="regular_300">
            {services}
          </StyledInfoDescription>
        </StyledInfoContainer>
      )}
      {isNotEmptyString(teamSize) && (
        <StyledInfoContainer>
          <StyledInfoTitle variant="strong_200">Team size:</StyledInfoTitle>
          <StyledInfoDescription variant="regular_300">
            {teamSize}
          </StyledInfoDescription>
        </StyledInfoContainer>
      )}
    </>
  );
};
