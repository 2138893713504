import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';

import { SubmenuBlock } from '../SubmenuBlock';
import type { ProductsSubmenuType } from './ProductsSubmenu.types';
import { productsSubmenuData } from './productsSubmenuData';
import { Wrapper, StyledStack } from './styles';

export const ProductsSubmenu = ({
  ...props
}: ProductsSubmenuType): ReactJSXElement => {
  return (
    <StyledStack>
      <Wrapper>
        {productsSubmenuData.blocks.map((block, index) => (
          <SubmenuBlock
            iconPrefix="products"
            type="icon"
            key={index}
            name={block.name}
            caption={block.caption}
            icon={block.icon}
            linkTo={block.linkTo}
            targetBlank={block?.targetBlank ?? false}
          />
        ))}
      </Wrapper>
    </StyledStack>
  );
};
