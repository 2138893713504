import { DevaWalletIcon, EvmDebuggerIcon } from '../Icons';
import { DevilWalletIcon } from '../Icons/DevilWallet/DevilWalletIcon/DevilWalletIcon';
import type { SubmenuBlockProps } from '../SubmenuBlock/SubmenuBlock.types';
const blocks = [
  {
    name: 'EVM Debugger',
    caption:
      'EVM Debugger is a unique, free tool for EVM & Defi developers. It enables really easy and user-friendly analysis of Ethereum smart contracts.',
    icon: EvmDebuggerIcon,
    linkTo: '/evm-debugger',
  },
  {
    name: 'Deva Wallet',
    caption:
      'Deva Wallet is a unique mobile wallet redefining the way users interact with Web3 and smart contracts. We use Account Abstraction to enable user-friendly onboarding, easy recovery, enhanced security and full compatibility with WalletConnect.',
    icon: DevaWalletIcon,
    linkTo: '/deva-wallet',
    targetBlank: true,
  },
];
const productsToShow = ['EVM Debugger', 'Deva Wallet'];
export const productsSubmenuData: {
  blocks: SubmenuBlockProps[];
} = {
  blocks: blocks.filter((block) => productsToShow.includes(block.name)),
};
