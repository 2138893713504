import { Box, Stack, styled } from '@mui/material';

export const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(12, 0, 24),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  },
}));

export const StyledTextWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'descMarginBottom' && prop !== 'height',
})<{ descMarginBottom?: boolean; height?: string }>(
  ({ theme, descMarginBottom, height }) => ({
    maxWidth: '490px',
    position: 'sticky',
    height: height || '750px',
    top: 120,
    marginBottom: descMarginBottom ? '300px' : 0,
    [theme.breakpoints.down('lg')]: {
      maxWidth: '400px',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2rem',
      marginBottom: '50px',
      height: 'auto',
      position: 'static',
      top: 'unset',
      width: '100%',
      maxWidth: 'unset',
    },
  })
);

export const StyledRightWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '50%',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
    width: '100%',
  },
}));

export const StyledOffersWrapper = styled(Stack)(({ theme }) => ({
  maxWidth: '476px',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '400px',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
  },
}));

export const StyledOneElement = styled(Stack)(({ theme }) => ({
  color: 'white',
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(22),
    paddingRight: theme.spacing(22),
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(16),
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
  },
}));

export const StyledOfferBox = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'height',
})<{ isActive: boolean; height?: string }>(({ theme, isActive, height }) => ({
  opacity: isActive ? 1 : 0.5,
  transition: 'ease-in-out 0.2s',

  [theme.breakpoints.up('md')]: {
    '&:last-child': {
      minHeight: height || '750px',
    },
  },
}));

export const NeonCircle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color?: string }>(({ theme, color }) => {
  const palette = theme.palette as unknown as {
    [key: string]: { [key: string]: string };
  };
  const shadowColor = palette[`${color}Colors`]?.PLUS_1;
  return {
    width: '4rem',
    height: '4rem',
    marginRight: '0.8rem',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.6rem',
    fontWeight: '400',
    color: 'white',
    textShadow: `0 0 2px ${shadowColor}, 0 0 1px #00FFFF, 0 0 3px ${shadowColor}`,
    background: 'transparent',
    border: `2px solid ${shadowColor}`,
    boxShadow: `0 0 5px ${shadowColor}, 0 0 2px ${shadowColor}, 0 0 20px ${shadowColor}, 
                0 0 2px rgba(0, 255, 255, 0.7)`,
    [theme.breakpoints.down('md')]: {
      width: '3rem',
      height: '3rem',
      fontSize: '1.4rem',
    },
  };
});
