import { Box } from '@mui/material';
import React from 'react';
import chequered from '../../../assets/webp/fromPNG/chequered.webp';
import {
  BorderStack,
  ContentWrapper,
  HeadingWrapper,
  MenuCircles,
  StyledButtonWrapper,
  StyledHeroDescription,
  StyledHeroHeading,
  StyledHeroSection,
  StyledKeepScrolling,
  StyledLink,
  StyledMacWindow,
  StyledScheduleCallArea,
  StyledStack,
  StyledUnderHeading,
} from './MacBookWindow.styles';
import type { MacBookWindowProps } from './MacBookWindow.types';

import { Button } from '../Button';
import { resolveButtonShadow, resolveGradient } from './MacBookWindow.utils';

export const MacBookWindow = ({
  title,
  description,
  additionalDescription,
  buttonLink,
  buttonText,
  bannerRef,
  gradient = 'orange-purple',
}: MacBookWindowProps) => {
  return (
    <StyledHeroSection width="normal">
      <StyledStack>
        <Box
          sx={(theme) => ({
            ['&:before']: {
              content: "''",
              height: `113%`,
              width: `100vw`,
              left: '0%',
              top: '0%',
              margin: `0 calc((-1) * ((100vw - 100%) / 2))`,
              position: 'absolute',
              opacity: 0.4,
              willChange: 'opacity',
              backgroundImage: `url(${resolveGradient(
                gradient
              )}), url(${chequered})`,
              backgroundSize: `100% 100%, 100%, 100%`,
              backgroundRepeat: `no-repeat`,
              backgroundPositionX: `10%, 100%, 70%`,
              backgroundPositionY: `10%, 2%`,
              [theme.breakpoints.down('md')]: {
                backgroundSize: `120% 100%, 100%`,
                backgroundPositionX: `50%, 0%, 0%`,
              },
              [theme.breakpoints.down('sm')]: {
                backgroundSize: `130% 100%, 100%`,
              },
              [theme.breakpoints.down(500)]: {
                backgroundSize: `170% 100%, 100%`,
              },
              [theme.breakpoints.down(400)]: {
                backgroundSize: `225% 100%, 100%`,
              },
            },
          })}></Box>

        <StyledMacWindow ref={bannerRef}>
          <BorderStack>
            <MenuCircles>
              <div />
              <div />
              <div />
            </MenuCircles>
          </BorderStack>

          <ContentWrapper>
            <HeadingWrapper>
              <StyledHeroHeading variant="display_sm">
                {title}
              </StyledHeroHeading>

              <StyledUnderHeading variant="regular_600">
                {description}
              </StyledUnderHeading>
            </HeadingWrapper>

            {additionalDescription && (
              <StyledHeroDescription variant="regular_500">
                {additionalDescription}
              </StyledHeroDescription>
            )}

            <StyledScheduleCallArea>
              <StyledHeroDescription variant="regular_400"></StyledHeroDescription>
              <StyledButtonWrapper>
                <StyledLink to={buttonLink}>
                  <Button
                    sx={{
                      '.MuiStack-root': {
                        backgroundColor: 'transparent !important',
                        border: '1px solid white',
                        boxShadow: (theme) =>
                          resolveButtonShadow(gradient, theme),
                      },
                    }}>
                    {buttonText}
                  </Button>
                </StyledLink>
              </StyledButtonWrapper>
              <StyledKeepScrolling />
            </StyledScheduleCallArea>
          </ContentWrapper>
        </StyledMacWindow>
      </StyledStack>
    </StyledHeroSection>
  );
};
