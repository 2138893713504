import { Button, Grid, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React, { useMemo, useRef, useState } from 'react';
import Headline from '../Headline';
import type { CurrentOpeningsProps } from './CurrentOpenings.types';
import OpeningItem from './OpeningItem';
import { CustomJobPostingEntity } from '../../../../customStrapiTypes';

const PREFIX = 'CurrentOpenings';

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  button: `${PREFIX}-button`,
  dropdown: `${PREFIX}-dropdown`,
};

const Root = styled('div')(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    padding: '4rem 0',
    position: 'relative',
    paddingBottom: theme.spacing(5),
    '&::after': {
      content: "''",
      width: '120vw',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: '-20vw',
      background: 'rgba(1, 25, 111, 0.05)',
      zIndex: '-1',
    },
  },

  [`& .${classes.item}`]: {
    marginTop: theme.spacing(5),
  },

  [`& .${classes.button}`]: {
    textAlign: 'center',
  },

  [`& .${classes.dropdown}`]: {
    width: '100%',
    maxWidth: 410,
  },
}));

const StyledParagraph = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.greyColors}`,
  fontFamily: 'Rajdhani',
  fontSize: '1.2rem',
  fontWeight: 500,
  lineHeight: '100%',
  letterSpacing: '-2%',
}));

export const CurrentOpenings: React.FC<CurrentOpeningsProps> = ({ model }) => {
  const data = model.attributes?.jobPosting?.data;
  const email = 'karolina.s@rumblefish.pl';
  const [isExpanded, setIsExpanded] = useState(false);
  const [isRecruiting, setIsRecruiting] = useState(false);

  const buttonLabel = useRef<string | null>(null);
  const filteredData = useMemo(() => {
    const hasOffers = (data as CustomJobPostingEntity[])?.length > 0;
    setIsRecruiting(hasOffers);

    if (!isExpanded) {
      buttonLabel.current = 'Show More';
      return data?.slice(0, 3);
    } else {
      buttonLabel.current = 'Show Less';
      return data;
    }
  }, [data, isExpanded]);

  return (
    <Root className={classes.root}>
      <Grid container>
        <Headline headline="Current Openings" />
        {isRecruiting ? (
          <>
            <Grid py={4} item xs={12}></Grid>
            <Grid item xs={12}>
              {filteredData?.map((opening) => (
                <OpeningItem opening={opening} key={opening.id} />
              ))}
            </Grid>
          </>
        ) : (
          <StyledParagraph>
            We are not hiring at the moment, but you are welcome to submit your
            CV for future opportunities
          </StyledParagraph>
        )}
        <Grid item xs={12} className={clsx(classes.item, classes.button)}>
          {isRecruiting ? (
            <Button
              color="primary"
              variant="contained"
              onClick={() => setIsExpanded(!isExpanded)}>
              {buttonLabel.current}
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              component="a"
              href={`mailto:${email}`}>
              Submit your application
            </Button>
          )}
        </Grid>
      </Grid>
    </Root>
  );
};
