import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/system/Box';
import React from 'react';
import quote from '../../assets/webp/fromPNG/pinkQuote.webp';
import { colors } from '../../theme/default/color';
import type { PersonSectionProps } from './PersonSection.types';

export const PersonSection = ({
  text,
  img,
  personName,
  personPosition,
  imagePosition = 'left',
  whiteText = false,
}: PersonSectionProps) => {
  const color = whiteText ? colors.white : colors.black;
  const textAlign = imagePosition === 'left' ? 'left' : 'right';

  const textStyle = {
    variant: 'body2',
    fontSize: '1rem',
    color,
  } as const;

  return (
    <Grid
      container
      gap={{ xs: 2, md: 0 }}
      direction={{
        sm: 'column',
        md: imagePosition === 'left' ? 'row' : 'row-reverse',
      }}
      justifyContent={{ xs: 'center', md: 'space-between' }}
      alignItems="center">
      <img
        src={img}
        alt={`${personName} ${personPosition}`}
        height="289"
        width="289"
      />
      <Grid item xs={11} md={8}>
        <Box
          mb={1}
          width="38px"
          position="relative"
          left={{ sm: '10xp', md: imagePosition === 'left' ? '-10px' : '97%' }}>
          <img width="38px" height="28px" src={quote} alt="" />
        </Box>
        <Box textAlign={{ sm: 'left', md: textAlign }} justifyContent="center">
          <Typography {...textStyle} fontStyle="italic" mb={4}>
            {text}
          </Typography>
          <Typography {...textStyle} fontWeight="800" display="inline">
            {personName},{' '}
          </Typography>
          <Typography {...textStyle} display="inline">
            {personPosition}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
