import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import daniel from '../../assets/webp/fromJPG/daniel.webp';
import kacper from '../../assets/webp/fromJPG/kacper.webp';
import { PersonCard } from '../PersonCard/PersonCard';
import { Section } from '../Section';
import { SectionHeader } from '../SectionHeader';
import { List, StyledIframe } from './styles';

const candidate = [
  'is comfortable with object-oriented programming',
  'is passionate about smart contracts / Ethereum / cryptocurrencies',
  'believes that the correct ratio of test code vs feature code is 5+ : 1',
  'has an enthusiastic approach to working with bleeding-edge technologies',
  'is available for a 4-week paid training  ~40 hours a week',
];

const offer = [
  'a 4 week paid Blockchain training program',
  'technical mentor support',
  'a guarantee of stable employment after the training ends',
  'continuous growth and gaining further experience in one of our Blockchain projects',
];

export const BlockchainHubCourseSummary: React.FC = () => {
  return (
    <Section type="narrow">
      <Grid container direction="column" rowGap={3}>
        <SectionHeader
          title="Become a Blockchain Developer in just 4 weeks!"
          annotation="rumble fish course"
        />
        <Typography variant="body1">
          Our original 4-week Blockchain training program was designed to help
          software developers reach the level of expertise necessary to work in
          a DeFi project. Complete the training and Rumble Fish will guarantee
          you stable employment and a place in one of the Blockchain projects we
          lead. Don’t miss out on this opportunity, as places are limited.
        </Typography>
        <Typography variant="body1">
          Blockchain, since its beginning, has repeatedly proven to be a major
          changing force in software development. Many engineers dream of
          changing the industry and exploring the world of DeFi, NFTs,
          cryptocurrencies, stablecoins, and smart contracts. With Rumble Fish,
          you can make that happen in as short as 4 weeks.
        </Typography>
        <StyledIframe
          src="https://www.youtube-nocookie.com/embed/XWHDNHS2JI0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
        <Typography sx={{ mt: 2 }} variant="h2" component="h3">
          Our Alumni
        </Typography>

        <Grid
          container
          wrap="nowrap"
          direction={{ xs: 'column', md: 'row' }}
          gap={2}
          sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <PersonCard
              name="Daniel"
              image={daniel}
              content="Web3 development is a great adventure but it also brings its own, demanding, blockchain-specific challenges. RF Blockchain Training Hub gave me all required tools and knowledge to face them with confidence."
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PersonCard
              name="Kacper"
              image={kacper}
              content="I found Blockchain training to be very effective due to the practical tasks offered such as exchanging tokens and creating liquidity pools in combination with flash loans, thanks to which, you can understand the principles of Ethereum blockchain."
            />
          </Grid>
        </Grid>
        <Typography variant="h2" component="h3" sx={{ mt: 4 }}>
          The perfect candidate:
        </Typography>
        <ul>
          {candidate.map((candi) => (
            <List key={candi}>{candi}</List>
          ))}
        </ul>
        <Typography variant="h2" component="h3">
          What we offer:
        </Typography>
        <ul>
          {offer.map((offer) => (
            <List key={offer}>{offer}</List>
          ))}
        </ul>
      </Grid>
    </Section>
  );
};
