import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { Loader } from '../Loader';
import { SubmenuBlock } from '../SubmenuBlock';
import type { DividerProps, BlogSubmenuType } from './BlogSubmenu.types';
import {
  UpperStack,
  StyledStack,
  DividerSection,
  DividerWrapper,
  DividerSectionText,
  StyledMobileVisitBlogIcon,
  StyledDesktopVisitBlogIcon,
} from './styles';
import { useRouterEventually } from '../../../hooks';

export const Divider = ({
  children,
  linkTo,
  withoutIcon,
  ...props
}: DividerProps) => {
  const router = useRouterEventually();
  const handleOnClick = useCallback(() => {
    if (!linkTo) return;
    if (router) router.push(linkTo);
    else window.location.href = linkTo;
  }, [linkTo, router]);
  const shouldHaveHover = !withoutIcon ? 'HoverPossibility' : '';
  return (
    <DividerSection onClick={handleOnClick} {...props}>
      <DividerWrapper className={shouldHaveHover}>
        <DividerSectionText variant="strong_100">{children}</DividerSectionText>
        {!withoutIcon && (
          <>
            <StyledMobileVisitBlogIcon />
            <StyledDesktopVisitBlogIcon
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.9698 9.03015L9 15M10.7789 9L14.9698 9.02955L15 13.2211"
                stroke="currentColor"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </StyledDesktopVisitBlogIcon>
          </>
        )}
      </DividerWrapper>
    </DividerSection>
  );
};

export const BlogSubmenu = ({
  blogPosts,
}: BlogSubmenuType): ReactJSXElement => {
  const theme = useTheme();
  const postsQuantity = theme.utils.isMobile() ? 4 : 8;
  const postsToDisplay = blogPosts?.slice(0, postsQuantity) || null;

  return (
    <StyledStack>
      <Divider linkTo="/blog">Visit our blog</Divider>

      <UpperStack
        sx={
          !postsToDisplay
            ? { justifyContent: 'center', alignItems: 'center' }
            : undefined
        }>
        {!postsToDisplay && <Loader>Receiving blog posts</Loader>}
        {postsToDisplay &&
          postsToDisplay.map((post) => {
            const { attributes } = post;
            const category = attributes?.category && attributes?.category[0];
            const thumbnailIcon = attributes?.thumbnail
              ? attributes?.thumbnail?.data?.attributes?.url
              : attributes?.image?.data?.attributes?.url;
            return (
              <SubmenuBlock
                variant="resource"
                iconPrefix="Resources"
                type="square"
                key={post.id}
                name={attributes?.title || ''}
                caption={category?.name || ''}
                icon={thumbnailIcon || ''}
                linkTo={`/blog/post/${attributes?.slug || ''}`}
              />
            );
          })}
      </UpperStack>
    </StyledStack>
  );
};
