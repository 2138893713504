import { Stack, styled, Typography } from '@mui/material';
import chequered from '../../assets/webp/fromPNG/fintechGuide/chequered.webp';

export const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(5),
  margin: theme.spacing(0, 0, 8, 0),
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(0, 0, 0, 0),
    gap: theme.spacing(0),
  },
}));
export const Chequered = styled('div')(({ theme }) => ({
  ['&:before']: {
    content: "''",
    height: `100vh`,
    width: `100%`,
    left: '0%',
    top: '45%',
    position: 'absolute',
    zIndex: 0,
    backgroundImage: `url(${chequered})`,
    backgroundSize: `100% 65%`,
    backgroundRepeat: `no-repeat`,
    backgroundPositionX: `10%, 100%, 70%`,
    backgroundPositionY: `10%, 2%`,
    [theme.breakpoints.down('xl')]: {
      width: `100%`,
    },

    [theme.breakpoints.down('lg')]: {
      backgroundSize: `100% 55%`,
    },
    [theme.breakpoints.down('md')]: {
      top: `55%`,
      backgroundSize: `auto 70%`,
    },
  },
}));

export const StyledHeading = styled(Typography)(({ theme }) => ({
  background: '-webkit-linear-gradient(45deg, #FFFFFF 100%, #D9D9D9 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: '700',
  lineHeight: '64px',
  fontSize: '64px',
  letterSpacing: '-0.02em',
  textAlign: 'center',
  backgroundClip: 'text',
  zIndex: 10,
  margin: theme.spacing(0, 0, 5, 0),

  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '32px',
    lineHeight: '36.8px',
  },
}));

export const StyledBlocksStack = styled(Stack)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '1rem',
  justifyContent: 'space-evenly',
}));
export const Block = styled(Stack)(({ theme }) => ({
  width: '392px',
  display: 'flex',
  alignItems: 'flex-start',
  padding: theme.spacing(5),
  backdropFilter: 'blur(12px)',
  borderRadius: '8px',
  background:
    ' radial-gradient(47.45% 48.31% at -0.77% 0%, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%) , rgba(255, 255, 255, 0.01)',
  [theme.breakpoints.down('md')]: {
    width: '350px',
  },
}));

export const StyledBlocksText = styled(Typography)(({ theme }) => ({
  fontWeight: '650',
  lineHeight: '125%',
  fontSize: '24px',
  letterSpacing: '-1%',
  textAlign: 'left',
  slant: 0,
  color: '#FFFFFF',

  [theme.breakpoints.down('sm')]: {
    lineHeight: '125%',
    fontSize: '24px',
    textAlign: 'center',
    letterSpacing: '-0.01em',
  },
}));
export const Line = styled('div')(({ theme }) => ({
  height: '1px',
  width: '100%',
  backgroundColor: '#FFFFFF',
  opacity: 0.15,
  marginTop: theme.spacing(10),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const BottomText = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(6, 30, 0, 30),
  zIndex: 1,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '650',
  fontSize: '40px',
  lineHeight: '125%',
  letterSpacing: '-0.01em',
  color: '#FFFFFF',
  fontVariationSettings: 'slnt 0',
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(6, 15, 0, 15),
    fontSize: '35px',
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4, 0, 0, 0),
    fontSize: '30px',
  },

  [theme.breakpoints.down('sm')]: {
    lineHeight: '36.8px',
    fontSize: '24px',
    textAlign: 'center',
  },
}));
