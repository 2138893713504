import React from 'react';
import { NavLink } from '../NavLink';
import { Button } from '../../components/Button';
import { Button as NewDesignButton } from '@rumblefish/ui/Rumblefish23Theme';
import { NavigationButtonProps } from './NavigationButton.types';
import { ScreenReaderText } from '../ScreenReaderText';

export const NavigationButton: React.FC<NavigationButtonProps> = ({
  target,
  buttonText,
  className,
  ariaLabel,
  variant = 'contained',
  color = 'secondary',
  newDesign = false,
}) => {
  if (newDesign) {
    return (
      <NavLink exact to={target}>
        <NewDesignButton className={className} color={color}>
          {buttonText}
          <ScreenReaderText> {ariaLabel} </ScreenReaderText>
        </NewDesignButton>
      </NavLink>
    );
  }
  return (
    <NavLink exact to={target}>
      <Button className={className} variant={variant} color={color}>
        {buttonText}
        <ScreenReaderText> {ariaLabel} </ScreenReaderText>
      </Button>
    </NavLink>
  );
};
