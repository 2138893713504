import {
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  styled,
} from '@mui/material';

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: 'fit-content',
  backgroundColor: theme.palette.background.default,
  '& .MuiFormLabel-root': {
    padding: theme.spacing(1, 2, 1, 2),
    borderRadius: '50px',
    border: '2px solid',
    boxShadow: `0px 0px 6px 4px ${theme.palette.raspberryColors?.PLUS_1}, 0px 0px 6px 4px ${theme.palette.raspberryColors?.PLUS_1} inset !important`,
    background: theme.palette.background.default,
    zIndex: 1,
  },
}));

export const StyledInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(5),
    padding: theme.spacing(1, 2, 1, 2),
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.raspberryColors?.PLUS_1,
    marginRight: theme.spacing(1),
    transition: theme.transitions.create(['transform']),
  },

  '& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input': {
    minWidth: '200px',
    width: 'fit-content',
    padding: theme.spacing(1.5, 6, 1.5, 3),
  },

  '& .MuiInputBase-input': {
    position: 'relative',
    width: '200px',
    minWidth: 'fit-content',
    color: 'white',
    border: `1px solid ${theme.palette.raspberryColors?.PLUS_1}`,
    borderRadius: '50px !important',
    padding: theme.spacing(1.5, 4, 1.5, 4),
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontWeight: 700,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      borderColor: theme.palette.raspberryColors?.PLUS_1,
      boxShadow: `0px 0px 2px 1px ${theme.palette.raspberryColors?.PLUS_1}, 0px 0px 2px 1px ${theme.palette.raspberryColors?.PLUS_1} inset !important`,
    },
  },
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
  fontSize: '1.2rem',
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
  background: theme.palette.background.default,
  '&:hover': {
    background: theme.palette.raspberryColors?.PLUS_1,
  },
}));
