import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

import {
  viewBoxes,
  type CustomSvgIconsProps,
  type SvgPathProps,
} from './CustomSvgIcons.types';

const SvgPath = ({ shapeId }: SvgPathProps): ReactJSXElement => {
  return (
    <g id={shapeId}>
      <path d="M6 22H14C17.866 22 21 18.866 21 15C21 11.134 17.866 8 14 8H6V11H14C16.761 11 19 13.239 19 15C19 16.761 16.761 19 14 19H6V22Z" />
      <circle cx="13" cy="15" r="3" fill="url(#paint0_linear)" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="17"
          y1="12"
          x2="11"
          y2="18"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#027DFF" />
          <stop offset="0.495" stopColor="#FD7B03" />
          <stop offset="1" stopColor="#41ABDF" />
        </linearGradient>
      </defs>
    </g>
  );
};

export const DevaWalletIcon = ({
  shouldbegradient,
  transitionduration = 0.4,
  prefix,
  solidColor,
  iconType = 'normal',
  gradientColor,
  ...props
}: CustomSvgIconsProps): ReactJSXElement => {
  const currColorId = `${prefix}_devaWalletCurrentColorGradient`;
  const newColorId = `${prefix}_devaWalletNewColorGradient`;
  const shapeId = `${prefix}_devaWalletShape`;
  const theme = useTheme();
  const isLight = theme.palette.type === 'light';
  const colorsDark = {
    base: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.greyColors?.PLUS_8,
      stop: theme.palette.redColors?.PLUS_2,
    },
  };

  const colorsLight = {
    base: {
      start: theme.palette?.greyColors?.PLUS_4,
      stop: theme.palette.greyColors?.PLUS_2,
    },
    hover: {
      start: theme.palette?.redColors?.PLUS_3,
      stop: theme.palette.redColors?.PLUS_2,
    },
  };
  const svgColors = isLight ? colorsLight : colorsDark;
  return (
    <SvgIcon {...props} viewBox="0 2 18 26">
      {solidColor ? (
        <SvgPath
          shapeId={shapeId}
          iconType={iconType}
          solidColor={solidColor}
        />
      ) : (
        <>
          <defs>
            <linearGradient
              style={{
                transition: `${transitionduration}s`,
              }}
              id={currColorId}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%">
              <stop offset="0" stopColor={svgColors.base.start} />
              <stop offset="1" stopColor={svgColors.base.stop} />
            </linearGradient>
            <linearGradient id={newColorId} x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0" stopColor={svgColors.hover.start} />
              <stop offset="1" stopColor={svgColors.hover.stop} />
            </linearGradient>
          </defs>
          <g
            style={{
              stroke: `url("#${currColorId}")`,
            }}>
            <SvgPath
              shapeId={shapeId}
              iconType={iconType}
              solidColor={solidColor}
            />
          </g>
          <use
            xlinkHref={`#${shapeId}`}
            style={{
              stroke: `url("#${newColorId}")`,
              transition: `${transitionduration}s`,
              opacity: shouldbegradient ? 1 : 0,
            }}
          />
        </>
      )}
    </SvgIcon>
  );
};
