import { Stack, styled, Typography } from '@mui/material';
import { Button } from '../Button/Button';
import { motion } from 'framer-motion';

export const StyledAbsoluteContainer = styled(Stack)(({ theme }) => ({
  width: '265px',
  padding: theme.spacing(3, 0, 3, 3),
  position: 'fixed',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  zIndex: 10,
  right: 0,
  top: '30%',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    width: '200px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    alignItems: 'center',
    height: '130px',
    padding: theme.spacing(3, 3, 0, 3),
    bottom: 0,
    top: 'auto',
  },
}));

export const StyledMotionDiv = styled(motion.div)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.spacing(1, 0, 0, 1),
  display: 'flex',
  boxShadow: '-4px -10px 32px 0px rgba(0, 6, 37, 0.45)',
  overflow: 'hidden',
  padding: '1px 0px 1px 1px',
  background:
    ' radial-gradient(168.91% 117.40% at -0.00% 100.00%, rgba(255, 255, 255, 0.10) 0%, rgba(249, 39, 127, 0.00) 100%), rgba(255, 255, 255, 0.01)',

  [theme.breakpoints.down('md')]: {
    width: '60%',
    borderRadius: theme.spacing(1, 1, 0, 0),
    padding: '1px 1px 0px 1px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}));
export const StyledMainContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexDirection: 'column',
  display: 'flex',
  borderRadius: theme.spacing(1, 0, 0, 1),
  gap: theme.spacing(4),
  overflow: 'hidden',
  padding: theme.spacing(2, 3, 2, 3),
  background: '#121B1C',
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(1),
    gap: theme.spacing(3),
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexDirection: 'row',
    gap: theme.spacing(0),
    padding: theme.spacing(0),
    borderRadius: theme.spacing(1, 1, 0, 0),
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
export const StyledContentSection = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(3),
  },
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(0),
    padding: theme.spacing(1, 1),
  },
}));
export const StyledBannerText = styled(Typography)(({ theme }) => ({
  marginTop: '-60%',
  fontWeight: '600',
  fontSize: '20px',
  lineHeight: '30px',
  letterSpacing: '-1%',
  color: theme.palette.invertedContent?.PRIMARY,
  [theme.breakpoints.down('lg')]: {
    fontSize: '18px',
    lineHeight: '20px',
  },

  [theme.breakpoints.down('md')]: {
    marginTop: '0%',
    fontSize: '10px',
    lineHeight: '15px',
  },
}));

export const StyledBannerImage = styled('div')<{
  verticalBannerImage?: string;
  horizontalBannerImage?: string;
}>(({ theme, verticalBannerImage, horizontalBannerImage }) => ({
  backgroundImage: ` url(${verticalBannerImage})`,
  backgroundRepeat: 'no-repeat',
  width: '244px',
  backgroundSize: '100% auto',
  height: verticalBannerImage ? '300px' : '100px',
  marginTop: '-10%',
  marginLeft: '-14%',
  [theme.breakpoints.down('lg')]: {
    width: '190px',
    height: verticalBannerImage ? '260px' : '80px',
  },
  [theme.breakpoints.down('md')]: {
    backgroundImage: ` url(${horizontalBannerImage})`,
    width: horizontalBannerImage ? '240px' : '100px',
    height: '96px',
    marginTop: '0%',
    marginLeft: '0%',
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 0),

  '.MuiStack-root': {
    backgroundColor: 'transparent !important',
    border: '1px solid white',
    boxShadow: `0px 0px 6px 4px ${theme.palette.orangeColors?.PLUS_2}, 0px 0px 6px 4px ${theme.palette.orangeColors?.PLUS_2} inset !important`,
  },
}));
