/* eslint-disable @typescript-eslint/naming-convention */
import type { ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import noiseBg from '../../assets/webp/fromPNG/noise.webp';
import { breakpoints } from './breakpoints';
import { mixins } from './mixins';
import { overrides as components } from './overrides';
import { palette, paletteDark, paletteNavy } from './palette';
import type { ExtendedTypographyPropsVariantOverrides1 } from './typography';
import { typography } from './typography';
import type { ExtendedTypographyPropsVariantOverrides2 } from './typography2';
import { typography2 } from './typography2';
import { isMobile, fluidFont } from './utils';
import {
  IFluidSize,
  fluidSize,
  getViewportHeight,
  isPhone,
  isWebkit,
} from '../rumblefish23Theme/utils';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides
    extends ExtendedTypographyPropsVariantOverrides1,
      ExtendedTypographyPropsVariantOverrides2 {}
}
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}
declare module '@mui/material/styles' {
  interface Theme {
    utils: {
      getViewportHeight: () => number;
      isMobile: () => boolean;
      isPhone: () => boolean;
      isWebkit: () => boolean;
      fluidFont: (min: number, max: number) => string;
      fluidSize: ({
        minSize,
        maxSize,
        increase,
        maxBp,
        minBp,
      }: IFluidSize) => string;
    };
  }
  interface ThemeOptions {
    utils?: {
      getViewportHeight: () => number;
      isMobile: () => boolean;
      isPhone: () => boolean;
      isWebkit: () => boolean;
      fluidFont: (min: number, max: number) => string;
      fluidSize: ({
        minSize,
        maxSize,
        increase,
        maxBp,
        minBp,
      }: IFluidSize) => string;
    };
  }
}

const spacing = 8;

export const theme = createTheme({
  palette,
  spacing,
  typography: { ...typography, ...typography2 },
  components,
  breakpoints,
  mixins,
  utils: {
    isWebkit: isWebkit,
    isMobile: isMobile,
    isPhone: isPhone,
    fluidFont: fluidFont,
    fluidSize: fluidSize,
    getViewportHeight: getViewportHeight,
  },
} as ThemeOptions);

export const themeDark = createTheme({
  palette: paletteDark,
  spacing,
  typography: { ...typography, ...typography2 },
  components: {
    MuiTypography: {
      styleOverrides: { ...components.MuiTypography },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: `url(${noiseBg}), #121B1C`,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
        },
      },
    },
  },
  breakpoints,
  mixins,
  utils: {
    isWebkit: isWebkit,
    isMobile: isMobile,
    isPhone: isPhone,
    fluidFont: fluidFont,
    fluidSize: fluidSize,
    getViewportHeight: getViewportHeight,
  },
} as ThemeOptions);

export const themeNavy = createTheme({
  palette: paletteNavy,
  spacing,
  typography: { ...typography, ...typography2 },
  components: {
    MuiTypography: {
      styleOverrides: { ...components.MuiTypography },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: `url(${noiseBg}), #071D5A`,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
        },
      },
    },
  },
  breakpoints,
  mixins: {
    defaultTransition: mixins.defaultTransition,
    hoverTextLightBlue: {
      transition: 'all 0.3s ease-in-out',
      color: '#FFFFFF',
    },
    mobilePadding: mixins.mobilePadding,
    flexColumnStartStart: mixins.flexColumnStartStart,
  },
  utils: {
    isWebkit: isWebkit,
    isMobile: isMobile,
    isPhone: isPhone,
    fluidFont: fluidFont,
    fluidSize: fluidSize,
    getViewportHeight: getViewportHeight,
  },
} as ThemeOptions);
