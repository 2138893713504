/* eslint-disable @typescript-eslint/naming-convention*/
export enum SUBMENUS {
  SERVICES = 'services',
  BLOG = 'blog',
  CAREERS = 'careers',
  PRODUCTS = 'products',
}
/* eslint-enable @typescript-eslint/naming-convention*/

export const submenusWhichAction = {
  services: 'setServicesHover',
  blog: 'setBlogHover',
  products: 'setProductsHover',
  careers: 'setCareersHover',
};

export const submenuActionStateValues = {
  setServicesHover: 'servicesHover',
  setBlogHover: 'blogHover',
  setProductsHover: 'productsHover',
  setCareersHover: 'careersHover',
};
