import { Button, styled } from '@mui/material';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import WestRoundedIcon from '@mui/icons-material/WestRounded';

export const ArrowRight = styled(EastRoundedIcon)(({ theme }) => ({
  path: {
    color: theme.palette.invertedContent?.PRIMARY,
  },
}));
export const ArrowLeft = styled(WestRoundedIcon)(({ theme }) => ({
  path: {
    color: theme.palette.invertedContent?.PRIMARY,
  },
}));
export const StyledButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    transition: 'all 0s !important',
  },
  fontFamily: 'Satoshi',
  fontWeight: '600',
  lineHeight: '175%',
  letterSpacing: '-0.1px',
  textTransform: 'none',
  borderRadius: '69px',
  whiteSpace: 'nowrap',
  width: 'min-content',
  '&.MuiButton-wide': {
    width: 'auto',
  },

  padding: '0px',
  ...theme.mixins.defaultTransition,
  '& .MuiStack-root': {
    padding: `${theme.utils.fluidSize({
      minSize: 8,
      maxSize: 10,
    })} ${theme.utils.fluidSize({ minSize: 20, maxSize: 32 })}`,
    [theme.breakpoints.down('md')]: {
      padding: '8px 20px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '10px 32px',
    },
  },

  '&.MuiButton-primary': {
    '*': {
      color: '#FBFCFD',
    },

    ...theme.mixins.shadowButtonMd,
    '& .MuiStack-root': {
      width: '100%',
      background: theme.palette.blueColors?._0,
      borderRadius: '69px',
      transition: 'background 0.3s',
      '&:hover': { background: theme.palette.blueColors?.PLUS_1 },
    },
  },
  '&.MuiButton-flat': {
    background: theme.palette.invertedSubtleAction?.ENABLED,
    boxShadow: `0px 8px 24px 0px ${theme.palette.invertedColors?.PLUS_7}`,
  },

  '&.MuiButton-icon': {
    minWidth: 'auto',
    background: 'transparent',
    '& .MuiStack-root': {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
      padding: theme.spacing(0),
      '& .MuiTypography-root': {
        padding: theme.spacing(0),
        color: 'currentColor',
      },
    },
  },
  '&.MuiButton-link': {
    background: 'transparent',
    '& .MuiStack-root': {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
      padding: theme.spacing(0),
      '& .MuiTypography-root': {
        color: theme.palette.invertedContent?.PRIMARY,
      },
    },
  },
}));

type PaletteColors = {
  [key: string]: {
    PLUS_1?: string;
    PLUS_2?: string;
  };
};

export const StyledSecondaryButton = styled(Button)<{ shadowColor?: string }>(
  ({ shadowColor, theme }) => {
    const palette = theme.palette as unknown as PaletteColors;

    const buttonColor: string =
      palette?.[`${shadowColor}Colors`]?.PLUS_1 ?? 'white';
    const hoverColor: string =
      palette?.[`${shadowColor}Colors`]?.PLUS_2 ?? 'white';

    if (!shadowColor)
      return {
        '&.MuiButton-root': {
          transition: 'all 0s !important',
        },
        fontFamily: 'Satoshi',
        fontWeight: '600',
        lineHeight: '175%',
        letterSpacing: '-0.1px',
        textTransform: 'none',
        borderRadius: '69px',
        whiteSpace: 'nowrap',
        width: 'min-content',
        '&.MuiButton-wide': {
          width: 'auto',
        },
        padding: '0px',
        ...theme.mixins.defaultTransition,
        '&.MuiButton-primary': {
          '*': {
            color: '#000',
          },
          ...theme.mixins.shadowButtonMd,
          '& .MuiStack-root': {
            width: '100%',
            borderRadius: '69px',
            background: 'linear-gradient(90deg, #CFD6DD 0%, #F5F8FF 100%)',
            transition: 'transform 0.2s',
            outline: `4px solid rgba(255, 255, 255, 0.25)`,
            paddingInline: '30px',
            paddingBlock: '8px',
            '&:hover': {
              transform: 'scale(0.95)',
              background: 'linear-gradient(90deg, #CFD6DD 0%, #F5F8FF 100%)',
            },
          },
        },
      };
    return {
      backgroundColor: 'transparent !important',
      color: 'white',
      border: '1px solid white',
      borderRadius: '30px',
      paddingInline: '30px',
      paddingBlock: '8px',
      textTransform: 'none',
      lineHeight: '28px',
      fontSize: '16px',
      letterSpacing: '-0.1px',
      fontWeight: '600',
      boxShadow: `0px 0px 4px 3px ${buttonColor}, 0px 0px 1px 0px ${buttonColor} inset !important`,
      [`&:hover`]: {
        boxShadow: `0px 0px 4px 3px ${hoverColor}, 0px 0px 1px 1px ${hoverColor} inset !important`,
      },
    };
  }
);
