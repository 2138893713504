import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Link, Stack, styled, Typography, useTheme } from '@mui/material';
import { Checkbox, InputField, Button } from '@rumblefish/ui/Rumblefish23Theme';
import React, { useRef } from 'react';
import type ReCAPTCHA from 'react-google-recaptcha';
import LoadingIcon from '../../../assets/svg/RumbleFish23Theme/LoadingFormIcon.svg';
import type { ISendData } from '../../../hooks/useForm/types';
import { useForm } from '../../../hooks/useForm/useForm';
import AlertSnackbar from '../../AlertSnackbar';
import type { ContactFormProps } from './ContactForm.types';
import { contactFormFields } from './FieldsData';
import {
  StyledLoadingIcon,
  StyledStack,
  StyledButtonWrapper,
  StyledRecaptcha,
  CheckboxLabelText,
} from './styles';
import { useContactFormErrors } from './ContactForm.reducer';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const LinkLabel = ({ color }: { color?: string }) => {
  const theme = useTheme();
  return (
    <CheckboxLabelText variant="regular_200">
      I accept Rumble Fish{' '}
      <Link
        href="/privacy-policy"
        underline={'none'}
        color={color ? color : theme.palette.blueColors?._0}
        target="_blank">
        Privacy Policy
      </Link>
    </CheckboxLabelText>
  );
};

export const ContactForm = ({
  error: baseError,
  color,
  isCareersPage = false,
  emailSubject = 'Contact Form',
  ...props
}: ContactFormProps) => {
  const theme = useTheme();
  const captchaRef = useRef<ReCAPTCHA>(null);
  const { addError, error, isErrorOpen, setIsErrorOpen } =
    useContactFormErrors(baseError);

  const { data, methods, loading, file, fileError } = useForm(
    contactFormFields,
    isCareersPage,
    `${process.env.BACKEND_API}/v1/contact`,
    true,
    () => captchaRef.current?.reset(),
    () => {
      addError(`Please contact us on e-mail hello@rumblefish.pl`);
    }
  );

  const dataToSend: ISendData = {
    fullName: data.fullName.text,
    email: data.email.text,
    subject: emailSubject,
    message: data.communication.checked
      ? `I would like to receive communication from the team. ${data.message.text}`
      : data.message.text,
    targetEmail: isCareersPage ? 'jobs' : 'hello',
  };

  const palette = theme.palette as unknown as {
    [key: string]: { [key: string]: string };
  };
  const buttonColor = palette?.[`${color}Colors`]?.PLUS_2;

  return (
    <StyledStack direction="column" justifyContent="space-between" {...props}>
      <Box sx={{ zIndex: 1 }}>
        <Stack gap={4}>
          <InputField
            size="small"
            label="Full Name"
            onChangeEvent={(e) => methods.setInputField('fullName', e)}
            value={data.fullName.text}
            disabled={loading}
            errorMessage={data.fullName.errorMessage}
            variant="filled"
            inputId={contactFormFields.fullName.id}
          />
          <InputField
            size="small"
            label={isCareersPage ? 'Email' : 'Bussiness Email'}
            onChangeEvent={(e) => methods.setInputField('email', e)}
            value={data.email.text}
            disabled={loading}
            errorMessage={data.email.errorMessage}
            variant="filled"
            inputId={contactFormFields.email.id}
          />
          <InputField
            size="small"
            label="Message"
            onChangeEvent={(e) => methods.setInputField('message', e)}
            value={data.message.text}
            disabled={loading}
            errorMessage={data.message.errorMessage}
            variant="filled"
            inputId={contactFormFields.message.id}
          />
          {isCareersPage && (
            <>
              <Button
                component={'label'}
                role={undefined}
                tabIndex={-1}
                secondaryVariant
                sx={{
                  marginBottom: fileError || file ? '-1.5rem' : '0',
                }}>
                <CloudUploadIcon
                  sx={{
                    marginInline: '-0.5rem 1rem',
                  }}
                />
                Upload CV
                <VisuallyHiddenInput
                  type="file"
                  accept="pdf, doc, docx"
                  onChange={methods.setFileField}
                  disabled={loading}
                  required
                />
              </Button>
              {fileError && (
                <Typography
                  variant="regular_200"
                  color={theme.palette.error.light}>
                  {fileError}
                </Typography>
              )}
              {file && !fileError && (
                <Stack>
                  <Typography variant="regular_200" color={buttonColor}>
                    {file.name} uploaded
                  </Typography>
                  <Typography
                    variant="regular_200"
                    color={theme.palette.grey[500]}>
                    {(file.size / 1000).toPrecision(4)} KB
                  </Typography>
                </Stack>
              )}
            </>
          )}
        </Stack>
        <Stack>
          <Checkbox
            color={buttonColor}
            label={
              <CheckboxLabelText variant="regular_200">
                I wish to receive Rumble Fish email communication.
              </CheckboxLabelText>
            }
            onChangeEvent={() => methods.setCheckBoxField('communication')}
            value={data.communication.checked}
            disabled={loading}
            error={data.communication.isError}
            checkboxId={contactFormFields.communication.id}
          />

          <Checkbox
            color={buttonColor}
            label={<LinkLabel color={buttonColor} />}
            onChangeEvent={() => methods.setCheckBoxField('policy')}
            value={data.policy.checked}
            disabled={loading}
            error={data.policy.isError}
            checkboxId={contactFormFields.policy.id}
          />
        </Stack>

        <StyledRecaptcha
          sitekey={process.env.CAPTCHA_KEY as string}
          onChange={(value) => methods.setCaptcha(value)}
          ref={captchaRef}
        />
      </Box>
      <StyledButtonWrapper>
        <Button
          secondaryVariant
          shadowColor={color}
          onClick={() => methods.submitForm(dataToSend)}>
          {loading ? (
            <StyledLoadingIcon src={LoadingIcon} alt="Loading..." />
          ) : isCareersPage ? (
            'Apply'
          ) : (
            'Send your message'
          )}
        </Button>
      </StyledButtonWrapper>
      {error?.messages.map((msg, idx) => (
        <AlertSnackbar
          key={idx}
          openErrorMessage={isErrorOpen}
          setOpenErrorMessage={setIsErrorOpen}
          errorMessage={msg}
        />
      ))}
    </StyledStack>
  );
};
